import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  appButton: {
    height: '100%'
  },
  tableContainer: {
    width: '100%',
    marginBottom: 80
  },
  cardContainer: {
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  fontBold: {
    fontWeight: 'bold'
  },
  chip: {
    marginRight: 10,
    marginBottom: 10
  }
}))
