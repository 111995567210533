import { useState, useEffect, useCallback } from 'react'

// ui elements
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {
  DataGrid,
  GridColDef,
  GridPageChangeParams,
  GridValueFormatterParams
} from '@material-ui/data-grid'

// hooks
import useDebounced from 'hooks/useDebounced'

// components
import { AppButton, TagModal } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// icons
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

// types
import { ILang, IPaginationData, ITag, ITagModal } from 'types'

// styles
import styles from './styles'

const Tags = () => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 20

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.9,
      sortable: false
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      sortable: false,
      flex: 0.4
    },
    {
      field: 'language',
      hide: true
    },
    {
      field: '_id',
      headerName: 'Actions',
      flex: 0.2,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)

        const tagData: ITag = {
          _id: getValue('_id'),
          name: getValue('name'),
          language: getValue('language'),
          created_at: getValue('created_at')
        }

        return (
          <>
            <IconButton
              onClick={() => onEditTag(tagData)}
              color="default"
              component="span"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => onDeleteTag(tagData)}
              color="default"
              component="span"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    }
  ]

  // local state
  const [tags, setTags] = useState<ITag[]>([])
  const [languages, setLanguages] = useState<ILang[]>([])
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [tagEditData, setTagEditData] = useState<ITag | null>(null)
  const [tagModal, setTagModal] = useState<ITagModal>({
    open: false,
    mode: 'Add'
  })
  const [paginationData, setPaginationData] = useState<IPaginationData>({
    total: 0,
    page: 1
  })

  const fetchLanguages = useCallback(async () => {
    const fetchedLanguages = (await utils.REQ('get', utils.EP.LANGUAGES)).data
    setLanguages(fetchedLanguages)
    setSelectedLanguage(fetchedLanguages[0]._id)
  }, [])

  const fetchTags = useCallback(
    async (search: string = '', page: number = 1) => {
      const fetchedData = await utils.REQ(
        'get',
        `${utils.EP.TAGS}?language=${selectedLanguage}&search=${search}&limit=${RESULT_LIMIT}&page=${page}`
      )

      const fetchedTags: ITag[] = fetchedData.data

      setTags(fetchedTags)
      setPaginationData({ page, total: fetchedData.total })
    },
    [selectedLanguage]
  )

  useEffect(() => {
    fetchLanguages()
  }, [fetchLanguages])

  useEffect(() => {
    if (selectedLanguage) fetchTags()
  }, [fetchTags, selectedLanguage])

  // fetch tags with debounce
  const debouncedFetchTags = useDebounced(fetchTags)

  const onSearchQuery = (val: string) => {
    setSearchQuery(val)

    if (val.length > 2) debouncedFetchTags(val)
    else if (val.length === 0) fetchTags()
  }

  const onAddTag = () => {
    setTagEditData(null)
    toggleTagModal()
  }

  const onEditTag = (tagData: ITag) => {
    setTagEditData(tagData)
    toggleTagModal('Edit')
  }

  const onDeleteTag = async (tagData: ITag): Promise<void> => {
    await utils.REQ('delete', `${utils.EP.TAGS_DELETE}/${tagData._id}`)

    // refresh
    fetchTags()
  }

  const toggleTagModal = (mode: 'Add' | 'Edit' = 'Add'): void => {
    setTagModal((currState) => ({ mode, open: !currState.open }))
  }

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            onChange={(e) => onSearchQuery(e.target.value)}
            fullWidth
            label="Search tags..."
          />
        </Grid>
        <Grid item xs={2}>
          <AppButton onClick={onAddTag} className={classes.appButton}>
            Add tag
          </AppButton>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="year"
              variant="outlined"
              fullWidth
              value={selectedLanguage ?? ''}
              onChange={(e) => setSelectedLanguage(e.target.value as string)}
            >
              {languages.map((lang: ILang) => (
                <MenuItem key={lang._id} value={lang._id}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={tags.map((tag: ITag) => ({
            ...tag,
            id: tag._id,
            created_at: utils.helpers.formatDate(tag.created_at as string)
          }))}
          paginationMode="server"
          columns={columns}
          pageSize={RESULT_LIMIT}
          rowCount={paginationData.total}
          disableSelectionOnClick
          onPageChange={(params: GridPageChangeParams) =>
            fetchTags(searchQuery, params.page + 1)
          }
        />

        {/* MODAL */}
        <TagModal
          data={tagEditData}
          tagModal={tagModal}
          toggleModal={toggleTagModal}
          languages={languages}
          refresh={fetchTags}
        />
      </div>
    </Layout>
  )
}

export default Tags
