import { makeStyles, Theme } from '@material-ui/core'

// ui elements
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// icons
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = makeStyles((theme: Theme) => ({
  cardContainer: {
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fontBold: {
    fontWeight: 'bold'
  }
}))

type PropTypes = {
  title: string
  body: string
  onEdit: () => void
  onDelete: () => void
}

const FAQCard = (props: PropTypes) => {
  // styles
  const classes = styles()

  return (
    <Card className={classes.cardContainer}>
      <Box width="70%">
        <Typography className={classes.fontBold} variant="body1">
          {props.title}
        </Typography>
        <Box height={10} />
        <Typography variant="body2">{props.body}</Typography>
      </Box>

      <Box display={'flex'} justifyContent="flex-end" alignItems="center">
        <IconButton onClick={props.onEdit} color="default" component="span">
          <EditIcon />
        </IconButton>
        <IconButton onClick={props.onDelete} color="default" component="span">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  )
}

export default FAQCard
