import { useState, useEffect, useCallback } from 'react'

// ui elements
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { DataGrid, GridColDef } from '@material-ui/data-grid'

// components
import { AppButton, LanguageModal } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { ILang, ILangModal, ILangModalValues } from 'types'

// styles
import styles from './styles'

const Languages = () => {
  // styles
  const classes = styles()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'short_name',
      headerName: 'Short Name',
      flex: 1
    },
    {
      field: 'local_name',
      headerName: 'Local Name',
      flex: 1
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      sortable: false,
      flex: 0.5
    },
  ]

  // local state
  const [languages, setLanguages] = useState<ILang[]>([])
  const [langModal, setLangModal] = useState<ILangModal>({
    open: false,
    mode: 'Add'
  })

  const fetchLanguages = useCallback(async () => {
    setLanguages((await utils.REQ('get', `${utils.EP.LANGUAGES}`)).data)
  }, [])

  useEffect(() => {
    fetchLanguages()
  }, [fetchLanguages])

  const toggleLangModal = (): void => {
    setLangModal((currState) => ({ ...currState, open: !currState.open }))
  }

  const onAddOrEditLanguage = async (values: ILangModalValues): Promise<void> => {
    await utils.REQ('post', utils.EP.LANGUAGES_CREATE, values)
    fetchLanguages()
  }

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={10}>
          <TextField variant="outlined" fullWidth label="Search languages..." />
        </Grid>
        <Grid item xs={2}>
          <AppButton onClick={() => toggleLangModal()} className={classes.appButton}>
            Add language
          </AppButton>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={languages.map((lang: ILang) => ({
            ...lang,
            id: lang._id,
            created_at: utils.helpers.formatDate(lang.created_at)
          }))}
          columns={columns}
          pageSize={20}
          disableSelectionOnClick
          // checkboxSelection
        />

        {/* MODAL */}
        <LanguageModal
          onAddOrEditLanguage={onAddOrEditLanguage}
          langModal={langModal}
          toggleModal={toggleLangModal}
        />
      </div>
    </Layout>
  )
}

export default Languages
