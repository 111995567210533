import { useCallback, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
// ui elements
import { Box, Grid, Icon } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// components
import Layout from 'HOCs/Layout'

// styles
import styles from './styles'

// icons
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LayersIcon from '@material-ui/icons/Layers'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import utils from 'utils'
import { IDashboardCards } from 'types'
import { ApexOptions } from 'apexcharts'
import { fNumber } from 'utils/formatNumber'

const Home = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dashboardData, setDashboardData] = useState<IDashboardCards | null>(null)
  // styles
  const classes = styles()

  const fetchDashboardData = useCallback(async (page?: number) => {
    setIsLoading(true)

    const fetchedDashboardData = await utils.REQ('get', utils.EP.DASHBOARD_HOME)

    setIsLoading(false)
    setDashboardData(fetchedDashboardData)
  }, [])

  useEffect(() => {
    fetchDashboardData()
  }, [fetchDashboardData])

  const chartLabels = dashboardData?.popular_topics?.map((i) => i.name)

  const chartSeries = dashboardData?.popular_topics?.map((i) => i.count)
  console.log(chartSeries)

  const options: ApexOptions = {
    chart: {
      id: 'basic-bar',
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      sparkline: {
        enabled: false
      }
    },
    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: 0,
      curve: 'smooth',
      lineCap: 'round'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 4,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last'
      }
    },
    // States
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.04
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.88
        }
      }
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      categories: chartLabels
    },
    // Grid
    grid: {
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false
        }
      }
    },

    // Markers
    markers: {
      size: 0
    },

    // Tooltip
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName.toString()),
        title: {
          formatter: () => ''
        }
      }
    }
  }

  const series: ApexAxisChartSeries = [
    {
      data: chartSeries as number[]
    }
  ]

  return (
    <Layout>
      {!isLoading ? (
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="h4">Hi, Welcome</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box className={classes.box} boxShadow={2}>
              <Box className={classes.iconContainer} boxShadow={1}>
                <Icon component={EmojiPeopleIcon} />
              </Box>
              <Typography variant="h4" className={classes.cardTitles}>
                {dashboardData?.guru}
              </Typography>
              <Typography variant="body2">Gurus</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box className={classes.box} boxShadow={2}>
              <Box className={classes.iconContainer} boxShadow={1}>
                <Icon component={SupervisorAccountIcon} />
              </Box>
              <Typography variant="h4" className={classes.cardTitles}>
                {dashboardData?.user}
              </Typography>
              <Typography variant="body2">Users</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box className={classes.box} boxShadow={2}>
              <Box className={classes.iconContainer} boxShadow={1}>
                <Icon component={LayersIcon} />
              </Box>
              <Typography variant="h4" className={classes.cardTitles}>
                {dashboardData?.topics}
              </Typography>
              <Typography variant="body2">Topics</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box className={classes.box} boxShadow={2}>
              <Box className={classes.iconContainer} boxShadow={1}>
                <Icon component={QuestionAnswerIcon} />
              </Box>
              <Typography variant="h4" className={classes.cardTitles}>
                {dashboardData?.calls}
              </Typography>
              <Typography variant="body2">Questions</Typography>
            </Box>
          </Grid>

          {dashboardData?.popular_topics ? (
            <Grid item xs={9}>
              <Box className={classes.chartContainer} boxShadow={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Top Topics</Typography>
                </Grid>
                <Chart options={options} series={series} type="bar" width="100%" />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Box height={40}>
          <Typography variant="body2">Loading...</Typography>
        </Box>
      )}
    </Layout>
  )
}

export default Home
