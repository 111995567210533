import req from './request'
import { ENDPOINTS } from './endpoints'
import * as helpers from './helpers'

const utils = {
  REQ: req,
  EP: ENDPOINTS,
  helpers
}

export default utils
