import { useState, useEffect, useCallback, ChangeEvent, useMemo, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'

// ui elements
import {
  DataGrid,
  GridColDef,
  GridPageChangeParams,
  GridValueFormatterParams
} from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// components
import { AppButton } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// icons
// import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

// utils
import utils from 'utils'

// types
import { IRouteProps, ITopicGroup, IPaginationData } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{}>

const TopicGroups = (props: PropTypes) => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 20
  const DEBOUNCE_DURATION: number = 500

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: 1
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      sortable: false,
      flex: 0.2
    },
    {
      field: 'topics',
      hide: true
    },
    {
      field: '_id',
      headerName: 'Actions',
      flex: 0.2,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)

        const topicGroupData: ITopicGroup = {
          _id: getValue('_id'),
          name: getValue('name'),
          topics: getValue('topics'),
          created_at: getValue('created_at')
        }

        return (
          <IconButton
            onClick={() => onTopicGroupDelete(topicGroupData)}
            color="default"
            component="span"
          >
            <DeleteIcon />
          </IconButton>
        )
      }
    }
  ]

  // local state
  const [topicGroups, setTopicGroups] = useState<ITopicGroup[]>([])
  const [topicGroupSearchQuery, setTopicSearchQuery] = useState<string>('')
  const [topicPaginationData, setTopicPaginationData] = useState<IPaginationData>({
    total: 0,
    page: 1
  })
  const [sortBy, setSortBy] = useState<string>('created_at')
  const [searchTimeoutId, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null)

  // refs
  const searchRef = useRef('')
  const pageRef = useRef(1)

  const sortingOptions = useMemo(() => {
    return [
      { name: 'Alphabetical ASC', value: 'name.it' },
      { name: 'Alphabetical DESC', value: '-name.it' },
      { name: 'Created ASC', value: 'created_at' },
      { name: 'Created DESC', value: '-created_at' }
    ]
  }, [])

  const fetchTopicGroups = useCallback(
    async (page?: number) => {
      const topicGroupsData = await utils.REQ(
        'get',
        `${utils.EP.TOPIC_GROUPS}?sort=${sortBy}&field=name&search=${
          searchRef.current
        }&limit=${RESULT_LIMIT}&page=${page ?? pageRef.current}`
      )

      // setting current page number
      pageRef.current = topicGroupsData.page

      setTopicGroups(topicGroupsData.topicGroups)
      setTopicPaginationData({ page: topicGroupsData.page, total: topicGroupsData.total })
    },
    [sortBy]
  )

  useEffect(() => {
    fetchTopicGroups()
  }, [fetchTopicGroups])

  const onTopicGroupDelete = async (topicGroupData: ITopicGroup): Promise<void> => {
    await utils.REQ('delete', `${utils.EP.TOPIC_GROUPS_DELETE}/${topicGroupData._id}`)
    await fetchTopicGroups()
  }

  const onTopicGroupQueryChange = (value: string) => {
    setTopicSearchQuery(value)

    // using ref so it does not re-run fetchTopics useCallback
    searchRef.current = value

    if (searchTimeoutId) clearTimeout(searchTimeoutId)

    // setting page to 1 on search
    pageRef.current = 1

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      if (value.trim().length > 2) fetchTopicGroups()

      // if no query, fetch the topics from page 1
      if (value.trim().length === 0) fetchTopicGroups(1)
    }, DEBOUNCE_DURATION)

    setSearchTimeoutId(timeoutId)
  }

  const onCellClick = (field: string, id: string) => {
    if (field !== 'name') return
    props.history.push(`/topic-groups/edit/${id}`)
  }

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={7}>
          <TextField
            value={topicGroupSearchQuery}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onTopicGroupQueryChange(e.target.value)
            }
            variant="outlined"
            fullWidth
            label="Search topic groups..."
          />
        </Grid>
        <Grid item xs={3}>
          <Link style={{ textDecoration: 'none' }} to={'/topic-groups/add'}>
            <AppButton className={classes.appButton}>Add topic group</AppButton>
          </Link>
        </Grid>

        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="year"
              variant="outlined"
              fullWidth
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as string)}
            >
              {sortingOptions.map((sortingOption: any) => (
                <MenuItem key={sortingOption.name} value={sortingOption.value}>
                  {sortingOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={topicGroups.map((topicGroup: ITopicGroup) => ({
            ...topicGroup,
            id: topicGroup._id,
            created_at: utils.helpers.formatDate(topicGroup.created_at)
          }))}
          columns={columns}
          pageSize={RESULT_LIMIT}
          disableSelectionOnClick
          onCellClick={(cell) => onCellClick(cell.field, cell.row.id)}
          rowCount={topicPaginationData.total}
          paginationMode="server"
          onPageChange={(params: GridPageChangeParams) => {
            fetchTopicGroups(params.page + 1)
          }}
        />
      </div>
    </Layout>
  )
}

export default withRouter(TopicGroups)
