import dayjs from 'dayjs'

export const formatDate = (date: Date | string, withTime = false) => {
  return dayjs(date).format(`DD MMM YYYY ${withTime ? 'hh:mm A' : ''}`)
}

export const getLastWeek = () => {
  return dayjs().subtract(1, 'week').format('DD-MM-YYYY')
}

export const getLastDay = () => {
  return dayjs().subtract(1, 'day').format('DD-MM-YYYY')
}

export const secondsFormatter = (seconds: number) => {
  const mins: number = Math.floor(seconds / 60)
  const secs: number = seconds - mins * 60

  let minsFormatted: string = mins.toString()
  let secsFormatted: string = secs.toString()

  if (mins === 0) minsFormatted = '00'
  else if (mins < 10) minsFormatted = '0' + minsFormatted

  if (secs === 0) secsFormatted = '00'
  else if (secs < 10) secsFormatted = '0' + secsFormatted

  return `${minsFormatted}:${secsFormatted}`
}

export const formatCurrency = (amount: number) => {
  return (amount / 100).toFixed(2)
}
