import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  Fragment,
  useMemo,
  useRef
} from 'react'
import { Link, withRouter } from 'react-router-dom'

// ui elements
import {
  DataGrid,
  GridColDef,
  GridPageChangeParams,
  GridValueFormatterParams
} from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// components
import { AppButton } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// icons
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

// utils
import utils from 'utils'

// types
import { IRouteProps, ITopic, IPaginationData, ISampleQuestion } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{}>

const SampleQuestions = (props: PropTypes) => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 20
  const DEBOUNCE_DURATION: number = 500

  const columns: GridColDef[] = [
    {
      field: 'question',
      headerName: 'Question',
      sortable: false,
      flex: 1
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      flex: 0.3,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        return `€ ${(getValue('amount') / 100).toFixed(2)}`
      }
    },
    {
      field: 'duration',
      headerName: 'Duration',
      sortable: false,
      flex: 0.3,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        const time = getValue('duration')
        const minutes = Math.floor(time / 60)
        const seconds = time - minutes * 60
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
      }
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      sortable: false,
      flex: 0.2
    },
    {
      field: '_id',
      headerName: 'Actions',
      flex: 0.2,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)

        const questionData: ISampleQuestion = {
          _id: getValue('_id'),
          question: getValue('question'),
          duration: getValue('duration'),
          amount: getValue('amount'),
          topic: getValue('topic')
        }

        return (
          <>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/sample-questions/edit/${questionData._id}`}
            >
              <IconButton color="default" component="span">
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => onSampleQuestionDelete(questionData)}
              color="default"
              component="span"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    }
  ]

  // local state
  const [sampleQuestions, setSampleQuestions] = useState<ITopic[]>([])
  const [questionSearchQuery, setQuestionSearchQuery] = useState<string>('')
  const [topicPaginationData, setTopicPaginationData] = useState<IPaginationData>({
    total: 0,
    page: 1
  })
  const [sortBy, setSortBy] = useState<string>('created_at')
  const [searchTimeoutId, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null)

  // refs
  const searchRef = useRef('')
  const pageRef = useRef(1)

  const sortingOptions = useMemo(() => {
    return [
      { name: 'Alphabetical ASC', value: 'name.it' },
      { name: 'Alphabetical DESC', value: '-name.it' },
      { name: 'Created ASC', value: 'created_at' },
      { name: 'Created DESC', value: '-created_at' }
    ]
  }, [])

  const fetchSampleQuestions = useCallback(
    async (page?: number) => {
      const sampleQuestionsData = await utils.REQ(
        'get',
        `${utils.EP.SAMPLE_QUESTIONS}?sort=${sortBy}&search=${
          searchRef.current
        }&field=question&limit=${RESULT_LIMIT}&page=${page ?? pageRef.current}`
      )

      // setting current page number
      pageRef.current = sampleQuestionsData.page

      setSampleQuestions(sampleQuestionsData.data)
      setTopicPaginationData({
        page: sampleQuestionsData.page,
        total: sampleQuestionsData.total
      })
    },
    [sortBy]
  )

  useEffect(() => {
    fetchSampleQuestions()
  }, [fetchSampleQuestions])

  const onSampleQuestionDelete = async (questionData: ISampleQuestion): Promise<void> => {
    await utils.REQ('delete', `${utils.EP.SAMPLE_QUESTIONS_DELETE}/${questionData._id}`)
    await fetchSampleQuestions()
  }

  const onQuestionQueryChange = (value: string) => {
    setQuestionSearchQuery(value)

    // using ref so it does not re-run fetchTopics useCallback
    searchRef.current = value

    if (searchTimeoutId) clearTimeout(searchTimeoutId)

    // setting page to 1 on search
    pageRef.current = 1

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      if (value.trim().length > 2) fetchSampleQuestions()

      // if no query, fetch the topics from page 1
      if (value.trim().length === 0) fetchSampleQuestions(1)
    }, DEBOUNCE_DURATION)

    setSearchTimeoutId(timeoutId)
  }

  const onCellClick = (field: string, id: string) => {
    if (field !== 'name') return

    props.history.push(`/topic/${id}`)
  }

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <TextField
            value={questionSearchQuery}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onQuestionQueryChange(e.target.value)
            }
            variant="outlined"
            fullWidth
            label="Search topics..."
          />
        </Grid>
        <Grid item xs={2}>
          <Link style={{ textDecoration: 'none' }} to={'/sample-questions/create'}>
            <AppButton className={classes.appButton}>Add question</AppButton>
          </Link>
        </Grid>

        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="year"
              variant="outlined"
              fullWidth
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as string)}
            >
              {sortingOptions.map((sortingOption: any) => (
                <MenuItem key={sortingOption.name} value={sortingOption.value}>
                  {sortingOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={sampleQuestions.map((topic: ITopic) => ({
            ...topic,
            id: topic._id,
            created_at: utils.helpers.formatDate(topic.created_at)
          }))}
          columns={columns}
          pageSize={RESULT_LIMIT}
          disableSelectionOnClick
          onCellClick={(cell) => onCellClick(cell.field, cell.row.id)}
          rowCount={topicPaginationData.total}
          paginationMode="server"
          onPageChange={(params: GridPageChangeParams) => {
            fetchSampleQuestions(params.page + 1)
          }}
        />
      </div>
    </Layout>
  )
}

export default withRouter(SampleQuestions)
