import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  iconContainer: {
    width: '60px',
    height: '60px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.palette.background.default
  },
  cardTitles: {
    marginTop: '1rem',
    fontWeight: 'bold'
  },
  chartContainer: {
    padding: theme.spacing(4),
    backgroundColor: 'white',
    borderRadius: '1rem',
    // display: 'flex',
    // flex: 1,
    // width: '100%'
  }
}))