import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  cardContent: {
    padding: theme.spacing(4)
  }
}))
