import { useState, useEffect, useCallback, ChangeEvent } from 'react'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions
} from '@material-ui/lab/Autocomplete'

// components
import { AppButton } from 'components'

// hooks
import useDebounced from 'hooks/useDebounced'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { ILang, ITag, IRouteProps, IChipData, ITopic, ITagTextFieldsData } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{ id: string }>

const Topic = (props: PropTypes) => {
  // styles
  const classes = styles()

  const filter = createFilterOptions<ITag>()

  // contants
  const topicId: string = props.match.params.id
  const DEBOUNCE_DURATION: number = 500
  const RESULT_LIMIT: number = 20

  // local state
  const [topic, setTopic] = useState<ITopic | null>(null)
  const [topics, setTopics] = useState<ITopic[]>([])
  const [languages, setLanguages] = useState<ILang[]>([])
  const [tags, setTags] = useState<ITag[]>([])
  const [tagsChipData, setTagsChipData] = useState<IChipData>({})
  const [tagTextFields, setTagTextFields] = useState<ITagTextFieldsData>({})
  const [debounceTimerId, setDebouceTimerId] = useState<NodeJS.Timeout | null>(null)

  const fetchTopic = useCallback(async (): Promise<void> => {
    const topic: ITopic = await utils.REQ('get', `${utils.EP.TOPIC}/${topicId}`)
    const tags: IChipData = {}

    // mapping the tags data to be set to the tagsChipData
    topic?.tags?.forEach((langWithTags: { language: string; tags: ITag[] }) => {
      tags[langWithTags.language] = langWithTags.tags
    })

    setTagsChipData(tags)
    setTopic(topic)
  }, [topicId])

  const fetchTopics = async (query: string): Promise<void> => {
    try {
      if (query.trim().length > 2) {
        const topicsData = await utils.REQ(
          'get',
          `${utils.EP.TOPICS}?field=name.it&limit=${RESULT_LIMIT}&search=${query}`
        )

        setTopics(topicsData.data)
      } else {
        setTopics([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const debouncedFetchTopics = useDebounced(fetchTopics)

  const fetchLanguages = useCallback(async () => {
    const fetchedLanguages: ILang[] = (await utils.REQ('get', `${utils.EP.LANGUAGES}`))
      .data

    const langFields: ITagTextFieldsData = {}

    for (let langIndex in fetchedLanguages) {
      langFields[fetchedLanguages[langIndex]._id] = {
        [fetchedLanguages[langIndex].name]: '',
        autocompleteOpen: false
      }
    }

    setTagTextFields(langFields)
    setLanguages(fetchedLanguages)
  }, [])

  useEffect(() => {
    fetchTopic()
    fetchLanguages()
  }, [fetchTopic, fetchLanguages])

  const fetchTagsByLang = useCallback(
    async (value: string, langId: string): Promise<void> => {
      setTags(
        (await utils.REQ('get', `${utils.EP.TAGS}?language=${langId}&search=${value}`))
          .data
      )
    },
    []
  )

  const onDeboucedLangSearch = (value: string, langId: string, langName: string) => {
    // clear the timeout if user presses a key
    if (debounceTimerId) clearTimeout(debounceTimerId)

    // start a search after a specified duration
    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      if (value.length > 2) fetchTagsByLang(value, langId)

      // setting input field values
      setTagTextFields((currState: ITagTextFieldsData) => ({
        ...currState,
        [langId]: { [langName]: value, autocompleteOpen: value.length > 2 ? true : false }
      }))
    }, DEBOUNCE_DURATION)

    setDebouceTimerId(timeoutId)
  }

  const onTagSelect = (passedTag: ITag | null, langId: string): void => {
    if (!passedTag) return

    const tag = {
      _id: passedTag._id,
      name: passedTag.name
    }

    const langTagsArr: ITag[] = tagsChipData[langId]

    // if the array is not present, make one and the tag to it
    if (!langTagsArr) {
      setTagsChipData((currState: IChipData) => {
        return {
          ...currState,
          [langId]: [...(currState[langId] ? currState[langId] : []), tag]
        }
      })
    }
    // else if the array is present, check if the tag already exists
    else {
      const tagExistingIndex: number = langTagsArr.findIndex(
        (langTag: ITag) => langTag._id === tag._id
      )

      // if tag exists, don't add it to the tag array
      if (tagExistingIndex !== -1) return

      // else add it
      setTagsChipData((currState: IChipData) => {
        return {
          ...currState,
          [langId]: [...(currState[langId] ? currState[langId] : []), tag]
        }
      })
    }
  }

  const onTagDelete = (tagId: string, langId: string): void => {
    setTagsChipData((currState: IChipData) => ({
      ...currState,
      [langId]: currState[langId].filter((tag: ITag) => tag._id !== tagId)
    }))
  }

  const closeAutocomplete = (lang: ILang): void => {
    setTagTextFields((currState: ITagTextFieldsData) => ({
      ...currState,
      [lang._id]: { [lang.name]: '', autocompleteOpen: false }
    }))
  }

  const getTagName = (name: string): string =>
    name.includes('Add') ? name.slice(5, name.length - 1) : name

  const onCreateTag = async (lang: ILang): Promise<void> => {
    let tagName: string = tagTextFields[lang._id][lang.name] as string

    // Ex of new tag: "Add 'fish pond'"
    const createdTag: ITag = await utils.REQ('post', utils.EP.TAGS_CREATE, {
      language: lang._id,
      name: getTagName(tagName)
    })

    // closing the autocomplete
    closeAutocomplete(lang)

    // adding the newly created tag to the chips
    setTagsChipData((currState: IChipData) => ({
      ...currState,
      [lang._id]: [
        ...(currState[lang._id] ? currState[lang._id] : []),
        { _id: createdTag._id, name: createdTag.name, language: createdTag.language }
      ]
    }))
  }

  const onCopyTagsFromAnotherTopic = async (_: ChangeEvent<{}>, topic: ITopic | null) => {
    if (!topic) return

    const topicData: ITopic = await utils.REQ('get', `${utils.EP.TOPIC}/${topic?._id}`)
    const tags: IChipData = JSON.parse(JSON.stringify(tagsChipData))

    // mapping the tags data to be set to the tagsChipData
    topicData?.tags?.forEach((langWithTags: { language: string; tags: ITag[] }) => {
      tags[langWithTags.language] = [
        ...(tags[langWithTags.language] ? tags[langWithTags.language] : []),
        ...langWithTags.tags
      ]
    })

    const uniqueTags: IChipData = {}

    // removing duplicates, if any
    Object.keys(tags).forEach((langId: string) => {
      const tagIds = tags[langId].map((tag) => tag._id)

      uniqueTags[langId] = tags[langId].filter(
        (tag: ITag, tagIndex: number) => !tagIds.includes(tag._id, tagIndex + 1)
      )
    })

    setTagsChipData(uniqueTags)
  }

  const onSave = async (): Promise<void> => {
    const data = {
      id: topicId,
      tags: Object.keys(tagsChipData).map((langId: string) => ({
        language: langId,
        tags: tagsChipData[langId].map((tag: ITag) => tag._id)
      }))
    }

    // sending tags data
    await utils.REQ('put', utils.EP.TOPICS_ATTACH_TAGS, data)

    // fetching topics data to get updated values
    fetchTopic()
  }

  return (
    <Layout>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" gutterBottom>
            {topic ? topic.name[Object.keys(topic.name)[0]].toUpperCase() : 'Loading...'}
          </Typography>

          <Box height={10} />

          <Divider />

          <Grid container direction="column">
            <Box height={30} />

            <Grid item xs={12}>
              <Autocomplete
                debug={true} // need this to override `open`
                options={topics}
                filterOptions={(options: ITopic[]) => {
                  return options.filter((topic) => topic._id !== topicId)
                }}
                open={!!topics.length}
                getOptionLabel={(option: ITopic) =>
                  `EN: ${option.name['en']}, IT: ${option.name['it']}`
                }
                getOptionSelected={(option: ITopic, value: ITopic) =>
                  option._id === value._id
                }
                blurOnSelect
                clearOnBlur
                onChange={onCopyTagsFromAnotherTopic}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    // value={tagTextFields[lang._id][lang.name]}
                    label={`Add tags from another topic to ${
                      topic ? topic.name[Object.keys(topic.name)[0]] : ''
                    }...`}
                    margin="normal"
                    size="medium"
                    onBlur={() => setTopics([])}
                    onChange={(
                      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      debouncedFetchTopics(e.target.value as string)
                    }}
                  />
                )}
              />
            </Grid>

            <Box height={50} />

            {languages.length ? (
              languages.map((lang: ILang) => (
                <Grid item xs key={lang._id}>
                  <Typography variant="h5">{lang.name}</Typography>
                  <Autocomplete
                    debug={true} // need this to override `open`
                    open={tagTextFields[lang._id]['autocompleteOpen']}
                    options={tags}
                    getOptionLabel={(option: ITag) => option.name}
                    getOptionSelected={(option: ITag, value: ITag) =>
                      option._id === value._id
                    }
                    blurOnSelect
                    clearOnBlur
                    onChange={(_, tag: ITag | null) => {
                      if (tag?.inputValue) onCreateTag(lang)
                      else onTagSelect(tag, lang._id)
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params)

                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          _id: lang.created_at, // any random id
                          language: lang._id,
                          name: `Add "${params.inputValue}"`,
                          inputValue: params.inputValue
                        })
                      }

                      return filtered
                    }}
                    onBlur={() => closeAutocomplete(lang)}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        value={tagTextFields[lang._id][lang.name]}
                        label={`Search for tags in ${lang.name}...`}
                        margin="normal"
                        size="medium"
                        onBlur={() => setTags([])}
                        onChange={(
                          e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                        ) => onDeboucedLangSearch(e.target.value, lang._id, lang.name)}
                      />
                    )}
                  />

                  <Box height={20} />

                  {/* TAGS AS CHIPS */}
                  {tagsChipData[lang._id] &&
                    tagsChipData[lang._id].map((tag: ITag) => (
                      <Chip
                        className={classes.chip}
                        key={tag._id}
                        color="primary"
                        variant="outlined"
                        label={tag.name}
                        onDelete={() => onTagDelete(tag._id, lang._id)}
                      />
                    ))}

                  <Box height={30} />
                </Grid>
              ))
            ) : (
              <Typography variant="body1">Loading...</Typography>
            )}

            <Box height={30} />

            <AppButton onClick={onSave}>Save</AppButton>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default Topic
