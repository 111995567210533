import { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueFormatterParams
} from '@material-ui/data-grid'

// icons

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { IQuestion, IRouteProps, ITopic } from 'types'

// styles
import styles from './styles'
import Typography from '@material-ui/core/Typography'

type PropTypes = IRouteProps<{}>

const Users = (props: PropTypes) => {
  // styles
  const classes = styles()

  const columns: GridColDef[] = [
    {
      field: 'question',
      headerName: 'Question',
      flex: 0.6
    },
    {
      field: 'topic',
      headerName: 'Topics',
      flex: 0.6,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        const topics = getValue('topic')
        return (
          <>
            {topics.map((t: ITopic) => (
              <p key={t._id}>{t.name.it}</p>
            ))}
          </>
        )
      }
    },
    {
      field: 'user',
      headerName: 'User',
      flex: 0.6,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        const user = getValue('user')
        return (
          <>
            {user ? (
              <MenuItem disableGutters>
                <ListItemIcon className={classes.profilePictureIcon}>
                  <img
                    src={user.profile_picture}
                    height={20}
                    width={20}
                    alt={user?.name + ' ' + user?.surname}
                  />
                </ListItemIcon>
                <Typography variant="inherit">
                  {user?.name + ' ' + user.surname}
                </Typography>
              </MenuItem>
            ) : (
              '--'
            )}
          </>
        )
      }
    },
    {
      field: 'guru',
      headerName: 'Guru',
      flex: 0.6,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        const guru = getValue('guru')
        return (
          <>
            {guru ? (
              <MenuItem disableGutters>
                <ListItemIcon className={classes.profilePictureIcon}>
                  <img
                    src={guru.profile_picture}
                    height={20}
                    width={20}
                    alt={guru?.name + ' ' + guru?.surname}
                  />
                </ListItemIcon>
                <Typography variant="inherit">
                  {guru?.name + ' ' + guru.surname}
                </Typography>
              </MenuItem>
            ) : (
              '--'
            )}
          </>
        )
      }
    },
    {
      field: 'created_at',
      headerName: 'Asked on',
      sortable: false,
      flex: 0.5
    }
    // {
    //   field: '_id',
    //   headerName: 'Tester',
    //   flex: 0.2,
    //   sortable: false,
    //   renderCell: (params: GridValueFormatterParams) => {
    //     const getValue = (field: string): any => params.getValue(params.id, field)

    //     const userData = {
    //       _id: getValue('_id'),
    //       name: getValue('name'),
    //       surname: getValue('surname'),
    //       mobile: getValue('mobile'),
    //       role: getValue('role'),
    //       tester: getValue('tester'),
    //       created_at: getValue('created_at')
    //     }

    //     return (
    //       <IconButton
    //         onClick={() => onToggleTester(userData._id, !!userData.tester)}
    //         title={userData.tester ? 'Remove tester access' : 'Add tester access'}
    //         color="default"
    //         component="span"
    //       >
    //         {userData.tester ? <NotInterestedIcon /> : <PersonAdd />}
    //       </IconButton>
    //     )
    //   }
    // }
  ]

  // local state
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [page, setPage] = useState(1)

  const fetchQuestions = useCallback(async () => {
    const fetchedQuestions = await utils.REQ(
      'get',
      `${utils.EP.QUESTIONS}?limit=20&search=${search}&page=${page}`
    )
    setQuestions(fetchedQuestions.data)
    setTotal(fetchedQuestions.total)
  }, [search, page])

  useEffect(() => {
    fetchQuestions()
  }, [fetchQuestions])

  // const onToggleTester = async (userId: string, isTester: boolean) => {
  //   await utils.REQ('put', utils.EP.USER_TESTER, { id: userId, tester: !isTester })
  //   fetchQuestions()
  // }

  return (
    <Layout>
      <TextField
        variant="outlined"
        fullWidth
        label="Search users..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={questions.map((question: IQuestion) => ({
            ...question,
            id: question._id,

            created_at: utils.helpers.formatDate(question.created_at, true)
          }))}
          columns={columns}
          pageSize={20}
          pagination
          page={page - 1}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            console.log('page changed')
            setPage(newPage.page + 1)
          }}
          disableSelectionOnClick
          onCellClick={(params: GridCellParams) => {
            const allowedCells = [
              'question',
              'category.name.it',
              'user.name',
              'guru.name',
              'created_at'
            ]
            if (allowedCells.includes(params.field)) {
              props.history.push(`/question/${params.id}`)
            }
          }}
        />
      </div>
    </Layout>
  )
}

export default withRouter(Users)
