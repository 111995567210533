import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

// logo
import Logo from 'assets/canguru-logo.png'

// ui elements
import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// components
import { AppButton } from 'components'

// global state
import { useApp } from 'providers/AppContextProvider'

// utils
import utils from 'utils'

// types
import { IResetPasswordDetails, IRouteProps } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{}>

const ResetPassword = (props: PropTypes) => {
  // styles
  const classes = styles()

  // global state
  const { setAlert } = useApp()

  // local state
  const [token, setToken] = useState<string>('')

  // react hook form
  const { handleSubmit, register } = useForm()

  useEffect(() => {
    const urlParams: URLSearchParams = new URLSearchParams(props.location.search)
    const urlToken: string | null = urlParams ? urlParams.get('token') : null

    urlToken ? setToken(urlToken) : props.history.push('/login')
  }, [props.location, props.history])

  const onResetPassword = async (values: IResetPasswordDetails): Promise<void> => {
    if (values.password.trim() !== values.confirm_password.trim()) {
      return setAlert({
        open: true,
        message: 'Both passwords should match',
        color: 'warning'
      })
    }

    await utils.REQ('post', utils.EP.AUTH_RESET_PASSWORD, {
      token,
      password: values.password
    })

    // going back to /login
    props.history.push('/login')
  }

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={Logo} width={80} alt="Canguru Logo" />
        </Avatar>
        <Typography className={classes.title} component="h1" variant="h5">
          Canguru
        </Typography>

        <form
          onSubmit={handleSubmit(onResetPassword)}
          className={classes.form}
          noValidate
        >
          <TextField
            {...register('password')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="New Password"
            type="password"
          />
          <TextField
            {...register('confirm_password')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Confirm Password"
            type="password"
          />

          <Box height={20} />

          <AppButton>reset password</AppButton>
        </form>
      </div>
    </Container>
  )
}

export default ResetPassword
