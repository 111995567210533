import { useState, useEffect, useCallback } from 'react'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// HOCs
import Layout from 'HOCs/Layout'

// types
import { IQuestion, IRouteProps, ITopic, IUser } from 'types'

// utils
import utils from 'utils'

// styles
import styles from './styles'
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'

type PropTypes = IRouteProps<{ id: string }>

const Question = (props: PropTypes) => {
  // styles
  const classes = styles()

  // local state
  const [question, setQuestion] = useState<IQuestion | null>(null)

  const fetQuestion = useCallback(async (): Promise<void> => {
    const fetchedQuestion: IQuestion = await utils.REQ(
      'get',
      `${utils.EP.QUESTION}/${props.match.params.id}`
    )

    console.log(fetchedQuestion)
    setQuestion(fetchedQuestion)
  }, [props.match.params.id])

  useEffect(() => {
    fetQuestion()
  }, [fetQuestion])

  return (
    <Layout>
      {question ? (
        <>
          <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar alt={question.user?.name} src={question.user?.profile_picture} />
              }
              title={question.user?.name + ' ' + question.user?.surname}
              subheader="User"
            />

            <CardContent className={classes.cardContent}>
              <Grid>
                <Grid item>
                  <Typography className={classes.title} color="textSecondary">
                    Question:
                  </Typography>
                  <Typography variant="h6">{question.question}</Typography>

                  <Box height={30} />

                  <Typography className={classes.title} color="textSecondary">
                    Topics:
                  </Typography>
                  {question.topic.map((t: ITopic) => (
                    <Chip label={t.name.it} className={classes.topicChip} />
                  ))}
                </Grid>
                <Box height={30} />
              </Grid>

              {question?.details && (
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Duration
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {question.details.duration
                            ? utils.helpers.secondsFormatter(question.details.duration)
                            : '0:00'}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Amount
                        </Typography>
                        <Typography variant="h6" component="h2">
                          €{' '}
                          {question.details?.amount
                            ? utils.helpers.formatCurrency(question.details.amount)
                            : '0.00'}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Application fee
                        </Typography>
                        <Typography variant="h6" component="h2">
                          €{' '}
                          {question.details?.application_fee
                            ? utils.helpers.formatCurrency(
                                question.details.application_fee
                              )
                            : '0.00'}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Guru earning
                        </Typography>
                        <Typography variant="h6" component="h2">
                          €{' '}
                          {question.details?.application_fee
                            ? utils.helpers.formatCurrency(
                                question.details.amount - question.details.application_fee
                              )
                            : '0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
            {question?.details && (
              <CardHeader
                avatar={
                  <Avatar
                    alt={question.guru?.name}
                    src={question.guru?.profile_picture}
                  />
                }
                title={question.guru?.name + ' ' + question.guru?.surname}
                subheader="Guru"
              />
            )}
          </Card>
        </>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
      <Box height={30} />
      {question ? (
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Grid container>
              {/* Notified  */}
              <Grid item xs>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Notified
                </Typography>
                <List>
                  {question.notified.map((user: IUser) => (
                    <ListItem disableGutters>
                      <ListItemAvatar>
                        <Avatar alt={user.name} src={user.profile_picture} />
                      </ListItemAvatar>
                      <ListItemText primary={user.name + ' ' + user.surname} />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              {/* Accpted  */}
              <Grid xs>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Accepted
                </Typography>
                <List>
                  {question.accepted.map((user: IUser) => (
                    <ListItem disableGutters>
                      <ListItemAvatar>
                        <Avatar alt={user.name} src={user.profile_picture} />
                      </ListItemAvatar>
                      <ListItemText primary={user.name + ' ' + user.surname} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Box height={30} />
            <Grid container>
              {/* Rejected  */}
              <Grid xs>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Rejected
                </Typography>
                <List>
                  {question.rejected.length ? (
                    question.rejected.map((user: IUser) => (
                      <ListItem disableGutters>
                        <ListItemAvatar>
                          <Avatar alt={user.name} src={user.profile_picture} />
                        </ListItemAvatar>
                        <ListItemText primary={user.name + ' ' + user.surname} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography>--</Typography>
                  )}
                </List>
              </Grid>

              {/* Cancelled  */}
              <Grid item xs>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Cancelled
                </Typography>
                <List>
                  {question.cancelled.length ? (
                    question.cancelled.map((user: IUser) => (
                      <ListItem disableGutters>
                        <ListItemAvatar>
                          <Avatar alt={user.name} src={user.profile_picture} />
                        </ListItemAvatar>
                        <ListItemText primary={user.name + ' ' + user.surname} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography>--</Typography>
                  )}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Layout>
  )
}

export default Question
