import { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { DateTimePicker } from '@material-ui/pickers'

// HOCs
import Layout from 'HOCs/Layout'

// components
import { AppButton } from '../../components'

// utils
import utils from 'utils'

// types
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

// styles
import styles from './styles'
import { IMaintenanceData } from 'types'

const Maintenance = () => {
  // styles
  const classes = styles()

  // local state
  const [launchTime, setLaunchTime] = useState<MaterialUiPickersDate>(dayjs())
  const [maintenanceTimings, setMaintenanceTimings] = useState<{
    start: MaterialUiPickersDate
    end: MaterialUiPickersDate
  }>({ start: dayjs(), end: dayjs() })
  const [timingErrors, setTimingErrors] = useState({ start: false, end: false })

  const fetchMaintenance = useCallback(async () => {
    const maintenanceData: IMaintenanceData = await utils.REQ('get', utils.EP.MAINTENANCE)

    setLaunchTime(dayjs(maintenanceData.launch))
    setMaintenanceTimings({
      start: dayjs(maintenanceData.maintenance_start),
      end: dayjs(maintenanceData.maintenance_end)
    })
  }, [])

  useEffect(() => {
    fetchMaintenance()
  }, [fetchMaintenance])

  const handleLaunchDateChange = (date: MaterialUiPickersDate) => {
    setLaunchTime(date)
  }

  const handleMaintenanceTimingChange = (
    date: MaterialUiPickersDate,
    type: 'start' | 'end'
  ) => {
    setMaintenanceTimings((currState) => ({ ...currState, [type]: date }))
  }

  const onSave = async (): Promise<void> => {
    if (!maintenanceTimings.start?.isBefore(maintenanceTimings.end)) {
      setTimingErrors((currState) => ({ ...currState, end: true }))
      return
    }

    setTimingErrors({ start: false, end: false })

    await utils.REQ('put', utils.EP.MAINTENANCE_UPDATE, {
      launch: launchTime,
      maintenance_start: maintenanceTimings.start,
      maintenance_end: maintenanceTimings.end
    })
  }

  return (
    <Layout>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} variant="body1">
            Maintenance Timings
          </Typography>
          <Box height={60} />

          <Grid container spacing={2}>
            <Grid item md={4}>
              <DateTimePicker
                fullWidth
                label="Launch Time"
                inputVariant="outlined"
                value={launchTime}
                onChange={handleLaunchDateChange}
              />
            </Grid>
            <Grid item md={4}>
              <DateTimePicker
                fullWidth
                label="Maintenance Start Timing"
                inputVariant="outlined"
                value={maintenanceTimings.start}
                onChange={(date) => handleMaintenanceTimingChange(date, 'start')}
              />
            </Grid>
            <Grid item md={4}>
              <DateTimePicker
                fullWidth
                inputVariant="outlined"
                label="Maintenance End Timing"
                value={maintenanceTimings.end}
                minDate={dayjs(maintenanceTimings.start)}
                error={timingErrors.end}
                helperText={
                  timingErrors.end && 'End time should have a later date than start time'
                }
                onChange={(date) => handleMaintenanceTimingChange(date, 'end')}
              />
            </Grid>

            <Grid item md={12}>
              <Box height={10} />
              <AppButton onClick={onSave}>Save</AppButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default Maintenance
