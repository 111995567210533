import { useForm } from 'react-hook-form'

// ui elements
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

// types
import { ILangModal, ILangModalValues } from 'types'

type PropTypes = {
  langModal: ILangModal
  toggleModal: () => void
  onAddOrEditLanguage: (values: ILangModalValues) => void
}

const LanguageModal = ({ langModal, toggleModal, onAddOrEditLanguage }: PropTypes) => {
  // react hook form
  const { handleSubmit, register } = useForm()

  const addOrEditLanguage = (values: ILangModalValues): void => {
    onAddOrEditLanguage(values)

    // closing the modal
    toggleModal()
  }

  return (
    <Dialog fullWidth open={langModal.open} onClose={toggleModal}>
      <DialogTitle>{langModal.mode} Language</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the below details to {langModal.mode.toLowerCase()} a language
        </DialogContentText>

        <form onSubmit={handleSubmit(addOrEditLanguage)} noValidate>
          <TextField
            {...register('name', { required: true })}
            autoFocus
            variant="outlined"
            required
            margin="normal"
            label="Name"
            fullWidth
          />
          <TextField
            {...register('short_name', { required: true })}
            variant="outlined"
            required
            margin="normal"
            label="Short name"
            fullWidth
          />
          <TextField
            {...register('local_name', { required: true })}
            variant="outlined"
            required
            margin="normal"
            label="Local name"
            fullWidth
          />

          <Box height={20} />

          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            disableElevation
            color="primary"
          >
            {langModal.mode} language
          </Button>

          <Box height={10} />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default LanguageModal
