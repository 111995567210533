import { useEffect, useCallback, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'

// ui elements
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

// types
import { ILang, ITag, ITagData, ITagModal } from 'types'

// utils
import utils from 'utils'

type PropTypes = {
  tagModal: ITagModal
  toggleModal: (mode: 'Add' | 'Edit') => void
  data: ITag | null
  languages: ILang[]
  refresh: () => void
}

const TagModal = (props: PropTypes) => {
  // react hook form
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' })

  const initialFormData: ITagData = useMemo(
    () => ({
      _id: '',
      name: '',
      language: null
    }),
    []
  )

  const resetForm = useCallback(() => {
    reset({ ...initialFormData })
  }, [reset, initialFormData])

  useEffect(() => {
    if (!props.tagModal.open) {
      resetForm()
    }
    if (props.tagModal.mode === 'Edit') {
      reset({ ...props.data })
    }
  }, [props.tagModal, props.data, reset, resetForm])

  const onSubmit = async (values: ITagData): Promise<void> => {
    if (props.tagModal.mode === 'Add') {
      await utils.REQ('post', utils.EP.TAGS_CREATE, {
        name: values.name.trim(),
        language: values.language && values.language._id
      })
    } else {
      await utils.REQ('put', utils.EP.TAGS_UPDATE, {
        id: values._id,
        name: values.name.trim(),
        language: values.language && values.language._id
      })
    }

    // refreshing the data
    props.refresh()

    // close modal
    props.toggleModal('Add')
  }

  return (
    <Dialog fullWidth open={props.tagModal.open} onClose={() => props.toggleModal('Add')}>
      <DialogTitle>{props.tagModal.mode} Tag</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the below details</DialogContentText>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid spacing={2} container>
            <Grid item md={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Name is required',
                  minLength: {
                    value: 2,
                    message: 'Name must should be at least 2 characters'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    margin="normal"
                    label="Name"
                    fullWidth
                    required
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item md={12}>
              <Controller
                name="language"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Language is required'
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Autocomplete
                    fullWidth
                    options={props.languages}
                    getOptionLabel={(option: ILang) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name}
                    value={value}
                    onChange={(_, item) => onChange(item)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        variant="outlined"
                        required
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box height={30} />

          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            disableElevation
            color="primary"
          >
            {props.tagModal.mode} tag
          </Button>

          <Box height={10} />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default TagModal
