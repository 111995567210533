import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  cardContent: {
    padding: theme.spacing(5)
  },
  chip: {
    marginRight: 10,
    marginBottom: 10
  }
}))
