import { useEffect } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'

// theme
import theme from 'theme/theme'

// views
import {
  Home,
  Login,
  ForgotPassword,
  ResetPassword,
  Users,
  User,
  Topics,
  TopicGroups,
  TopicGroupAdd,
  TopicGroupEdit,
  TopicSuggestions,
  Topic,
  Tags,
  Feedback,
  Languages,
  Earnings,
  EarningDetail,
  Maintenance,
  FAQs,
  Logs,
  DeleteRequests
} from 'views'

// HOCs
import LoadingAndMessage from 'HOCs/LoadingAndMessage'

// components
import { Navbar } from 'components'

// global state
import { useApp } from 'providers/AppContextProvider'

// utils
import utils from 'utils'

// types
import { IUser, IRouteProps } from 'types'
import Questions from 'views/Questions'
import Question from 'views/Questions/Question'
import SampleQuestions from 'views/SampleQuestions'
import SampleQuestionsAdd from 'views/SampleQuestions/SampleQuestionsAdd'
import SampleQuestionsEdit from 'views/SampleQuestions/SampleQuestionsEdit'

type PropTypes = IRouteProps<{}>

function App(props: PropTypes) {
  // global state
  const { setUser } = useApp()

  const isLoggedIn: boolean = !!localStorage.getItem('user')
  const currentPath: string = props.location.pathname

  useEffect(() => {
    ;(async () => {
      const user: IUser = JSON.parse(localStorage.getItem('user') || '{}')

      // if no user, and not in auth flow, send to /login
      const isInAuth =
        currentPath === '/login' ||
        currentPath === '/reset-password' ||
        currentPath === '/forgot-password'

      if (!user._id && !isInAuth) return props.history.replace('/login')
      else if (user._id) {
        const userResult: IUser = await utils.REQ('get', `${utils.EP.USER}/${user._id}`)

        // if user found, then store it in global state
        setUser({ ...userResult })
      }
    })()
  }, [props.history, currentPath, setUser])

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <LoadingAndMessage>
          <CssBaseline />
          {isLoggedIn ? <Navbar /> : null}

          <Switch>
            <Route exact path="/" component={Home} />

            {/* AUTH */}
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />

            <Route path="/users" component={Users} />
            <Route path="/user/:id" component={User} />
            <Route path="/questions" component={Questions} />
            <Route path="/question/:id" component={Question} />
            <Route path="/topics" component={Topics} />
            <Route exact path="/topic-groups" component={TopicGroups} />
            <Route path="/topic-groups/add" component={TopicGroupAdd} />
            <Route path="/topic-groups/edit/:id" component={TopicGroupEdit} />
            <Route path="/suggestions" component={TopicSuggestions} />
            <Route path="/topic/:id" component={Topic} />
            <Route path="/tags" component={Tags} />
            <Route path="/feedback" component={Feedback} />
            <Route path="/languages" component={Languages} />
            <Route path="/earnings" component={Earnings} />
            <Route path="/earning/:id" component={EarningDetail} />
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/faqs" component={FAQs} />
            <Route path="/logs" component={Logs} />
            <Route path="/delete-requests" component={DeleteRequests} />
            <Route path="/sample-questions" exact component={SampleQuestions} />
            <Route path="/sample-questions/create" component={SampleQuestionsAdd} />
            <Route path="/sample-questions/edit/:id" component={SampleQuestionsEdit} />
          </Switch>
        </LoadingAndMessage>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default withRouter(App)
