import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  title: {
    fontSize: 14
  },
  cardContent: {
    padding: theme.spacing(4)
  }
}))
