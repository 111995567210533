import { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react'

// ui elements
import Box from '@material-ui/core/Box'
import Pagination from '@material-ui/lab/Pagination'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'

// utils
import utils from 'utils'

// types
import { ISuggestion } from 'types'

// styles
import styles from './styles'

const KeywordSuggestions = () => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 15
  const SUGGESTION_TYPE = 'KEYWORD_SUGGESTION'

  // local state
  const [keywordSuggestions, setKeywordSuggestions] = useState<ISuggestion[] | null>(null)
  const [totalSuggestions, setTotalSuggestions] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // refs
  const pageRef = useRef(1)
  const searchRef = useRef('')

  const fetchKeywordSuggestions = useCallback(async (page?: number) => {
    setIsLoading(true)

    const suggestionData = await utils.REQ(
      'get',
      `${utils.EP.SUGGESTIONS}?group=${SUGGESTION_TYPE}&search=${
        searchRef.current
      }&limit=${RESULT_LIMIT}&page=${page ?? pageRef.current}`
    )

    // setting current page number
    pageRef.current = suggestionData.page

    setIsLoading(false)
    setKeywordSuggestions(suggestionData.data)
    setTotalSuggestions(suggestionData.total)
  }, [])

  useEffect(() => {
    fetchKeywordSuggestions()
  }, [fetchKeywordSuggestions])

  const onPageChange = (_: ChangeEvent<unknown>, page: number) => {
    fetchKeywordSuggestions(page)
  }

  return (
    <div>
      <Box height={40} />

      <div className={classes.tableContainer}>
        {isLoading && (
          <Box height={40}>
            <Typography variant="body2">Loading...</Typography>
          </Box>
        )}

        {keywordSuggestions && keywordSuggestions.length
          ? keywordSuggestions.map((suggestion) => {
              return (
                <Card key={suggestion._id} className={classes.cardContainer}>
                  <Box>
                    <Typography className={classes.fontBold} variant="body1">
                      User:{' '}
                      {suggestion.user
                        ? `${suggestion.user.name} ${suggestion.user.surname}`
                        : 'N/A'}
                    </Typography>
                    <Typography className={classes.fontBold} variant="body1">
                      Mobile: {suggestion.user ? suggestion.user.mobile : 'N/A'}
                    </Typography>
                    <Typography className={classes.fontBold} variant="body1">
                      On: {utils.helpers.formatDate(suggestion.created_at)}
                    </Typography>

                    <Box height={20} />

                    <Typography className={classes.fontBold} variant="body1">
                      Suggested Keyword:
                    </Typography>
                    <Box height={10} />
                    <Typography variant="body2">{suggestion.suggestion}</Typography>
                  </Box>
                </Card>
              )
            })
          : keywordSuggestions &&
            keywordSuggestions.length === 0 && (
              <Typography>No suggestions found</Typography>
            )}

        <Box height={30} />

        {keywordSuggestions && keywordSuggestions.length ? (
          <Box display="flex" justifyContent="center">
            <Pagination
              color="primary"
              count={Math.ceil(totalSuggestions / RESULT_LIMIT)}
              size="large"
              shape="rounded"
              onChange={onPageChange}
            />
          </Box>
        ) : null}
      </div>
    </div>
  )
}

export default KeywordSuggestions
