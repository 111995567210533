import { useState, ChangeEvent, useEffect, useMemo, useCallback } from 'react'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Autocomplete, {
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'

// components
import { AppButton } from 'components'

// hooks
import useDebounced from 'hooks/useDebounced'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { IRouteProps, ITopicGroup } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{ id: string }>

const SampleQuestionsEdit = (props: PropTypes) => {
  // styles
  const classes = styles()

  // params
  const sampleQuestionId = useMemo(() => props.match.params.id, [props.match.params.id])

  // local state
  const [question, setQuestion] = useState<string>('')
  const [amount, setAmount] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [topicQuery, setTopicQuery] = useState<string>('')
  const [topicGroups, setTopicGroups] = useState<ITopicGroup[]>([])
  const [topicGroup, setTopicGroup] = useState<ITopicGroup>()
  const [fieldErrors, setFieldErrors] = useState<{
    question: string | null
    topicGroup: string | null
    amount: string | null
    duration: string | null
  }>({ question: null, topicGroup: null, amount: null, duration: null })
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const fetchSampleQuestion = useCallback(async (): Promise<void> => {
    const sampleQuestion = await utils.REQ(
      'get',
      `${utils.EP.SAMPLE_QUESTIONS}/${sampleQuestionId}`
    )

    setQuestion(sampleQuestion.question)
    setAmount(sampleQuestion.amount)
    setDuration(sampleQuestion.duration)
    setTopicGroup(sampleQuestion.topic_group)
    setTopicQuery(sampleQuestion.topic_group.name)
  }, [sampleQuestionId])

  useEffect(() => {
    fetchSampleQuestion()
  }, [fetchSampleQuestion])

  const fetchTopicGroups = async (query: string): Promise<void> => {
    try {
      if (query.trim().length > 2) {
        const topicsData = await utils.REQ(
          'get',
          `${utils.EP.TOPIC_GROUPS}?field=name&search=${query}`
        )
        setTopicGroups(topicsData.topicGroups)
      } else {
        setTopicGroups([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const debouncedFetchTopics = useDebounced(fetchTopicGroups)

  useEffect(() => {
    let message: string | null = ''

    if (question.trim().length < 4) {
      message = 'Question must be at least 4 letters'
    } else {
      message = null
    }

    setFieldErrors((prevState) => ({
      ...prevState,
      question: message
    }))
  }, [question])

  useEffect(() => {
    let message: string | null = ''

    if (!amount || amount < 0) {
      message = 'Amount must be greater than 0'
    } else {
      message = null
    }

    setFieldErrors((prevState) => ({
      ...prevState,
      amount: message
    }))
  }, [amount])

  useEffect(() => {
    let message: string | null = ''

    if (!duration || duration < 0) {
      message = 'Duration must be greater than 0'
    } else {
      message = null
    }

    setFieldErrors((prevState) => ({
      ...prevState,
      duration: message
    }))
  }, [duration])

  useEffect(() => {
    let message: string | null = ''

    console.log(topicGroup)

    if (!topicGroup) {
      message = 'At least one topic must be provided'
    } else {
      message = null
    }

    setFieldErrors((prevState) => ({
      ...prevState,
      topicGroup: message
    }))
  }, [topicGroup])

  const onTopicSelect = (passedTopicGroup: ITopicGroup | null) => {
    if (!passedTopicGroup) return
    console.log(passedTopicGroup)
    setTopicGroup(passedTopicGroup)
  }

  const onSave = async (): Promise<void> => {
    console.log(fieldErrors)
    if (
      fieldErrors.question ||
      fieldErrors.topicGroup ||
      fieldErrors.amount ||
      fieldErrors.duration
    ) {
      setShowErrors(true)
      return
    } else {
      setShowErrors(false)
    }

    const data = {
      question,
      duration,
      amount,
      topic_group: topicGroup?._id,
      id: sampleQuestionId
    }

    // sending tags data
    await utils.REQ('put', utils.EP.SAMPLE_QUESTIONS_UPDATE, data)

    // resetting data
    setQuestion('')
    setAmount(0)
    setDuration(0)
    setTopicGroup(undefined)
    setTopicGroups([])
    setTopicQuery('')
  }

  return (
    <Layout>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body1" gutterBottom>
            EDIT SAMPLE QUESTION
          </Typography>

          <Box height={10} />

          <Divider />

          <Grid container direction="column">
            <Box height={30} />

            <Grid item xs={12}>
              <TextField
                value={question}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setQuestion(e.target.value)
                }}
                error={showErrors}
                helperText={showErrors && fieldErrors.question}
                variant="outlined"
                fullWidth
                label="Question"
              />
            </Grid>

            <Box height={20} />

            <Grid item xs={12}>
              <Autocomplete
                debug={true} // need this to override `open`
                options={topicGroups}
                open={!!topicGroups.length}
                getOptionLabel={(option: ITopicGroup) => option.name}
                getOptionSelected={(option: ITopicGroup, value: ITopicGroup) =>
                  option._id === value._id
                }
                blurOnSelect
                clearOnBlur
                onChange={(_, topicGroup: ITopicGroup | null) =>
                  onTopicSelect(topicGroup)
                }
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    value={topicQuery}
                    label={'Select topic for the question'}
                    margin="normal"
                    size="medium"
                    onBlur={() => setTopicGroups([])}
                    error={showErrors}
                    helperText={showErrors && fieldErrors.topicGroup}
                    onChange={(
                      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setTopicQuery(e.target.value)
                      debouncedFetchTopics(e.target.value as string)
                    }}
                  />
                )}
              />
            </Grid>

            <Box height={12} />

            <div>
              {/* TOPIC AS CHIPS */}
              {topicGroup && (
                <Chip
                  className={classes.chip}
                  key={topicGroup._id}
                  color="primary"
                  variant="outlined"
                  label={topicGroup.name}
                />
              )}
            </div>

            <Box height={20} />

            <Grid item xs={12}>
              <TextField
                value={amount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const num = Number(e.target.value)
                  if (Number.isInteger(num)) {
                    setAmount(num)
                  }
                }}
                error={showErrors}
                helperText={showErrors && fieldErrors.amount}
                variant="outlined"
                fullWidth
                label="Amount (in cents)"
              />
            </Grid>

            <Box height={20} />

            <Grid item xs={12}>
              <TextField
                value={duration}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const num = Number(e.target.value)
                  if (Number.isInteger(num)) {
                    setDuration(num)
                  }
                }}
                error={showErrors}
                helperText={showErrors && fieldErrors.duration}
                variant="outlined"
                fullWidth
                label="Duration (in seconds)"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Grid>

            <Box height={20} />

            <Box height={30} />
            <AppButton onClick={onSave}>Save</AppButton>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default SampleQuestionsEdit
