export enum ENDPOINTS {
  AUTH_LOGIN = '/auth/login',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_RESET_PASSWORD = '/auth/reset-password',

  USERS = '/users',
  USER = '/user',
  DELETE_REQUESTS = '/users/delete-requests',
  DELETE_USER = '/user',
  USER_TESTER = '/user/tester',

  TRANSACTIONS = '/earnings',

  TOPICS = '/topics',
  TOPIC = '/topic',
  TOPICS_CREATE = '/topics/create',
  TOPICS_UPDATE = '/topics/update',
  TOPICS_DELETE = '/topics/delete',
  TOPICS_ATTACH_TAGS = '/topics/tags/attach',
  TOPIC_SUGGESTIONS = '/topics/suggestion',

  SUGGESTIONS = '/suggestions',

  TOPIC_GROUPS = '/topic-groups',
  TOPIC_GROUPS_CREATE = '/topic-groups/create',
  TOPIC_GROUPS_UPDATE = '/topic-groups/update',
  TOPIC_GROUPS_DELETE = '/topic-groups',

  LANGUAGES = '/languages',
  LANGUAGES_CREATE = '/languages/create',
  LANGUAGES_UPDATE = '/languages/update',

  EARNINGS = '/monthly-earnings',
  EARNINGS_STATUS_UPDATE = 'monthly-earnings/set-status',

  TAGS = '/tags/search',
  TAGS_CREATE = '/tags/create',
  TAGS_UPDATE = '/tags/update',
  TAGS_DELETE = '/tags/delete',

  MAINTENANCE = '/maintenance',
  MAINTENANCE_UPDATE = 'maintenance/update',

  FAQS = '/faqs',
  FAQS_CREATE = '/faqs/create',
  FAQS_UPDATE = '/faqs/update',
  FAQS_DELETE = '/faqs/delete',

  LOGS = '/logs',
  LOGS_DELETE = '/logs/delete',
  QUESTIONS = '/questions',
  QUESTION = '/question',
  SAMPLE_QUESTIONS = '/sample-questions',
  SAMPLE_QUESTIONS_CREATE = '/sample-questions/create',
  SAMPLE_QUESTIONS_UPDATE = '/sample-questions/update',
  SAMPLE_QUESTIONS_DELETE = '/sample-questions/delete',

  DASHBOARD_HOME = '/dashboard/home'
}
