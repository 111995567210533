import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  appButton: {
    height: '100%'
  },
  tableContainer: {
    width: '100%',
    marginBottom: 80
  }
}))
