import { useState, useEffect, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import {
  DataGrid,
  GridCellValue,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
  GridPageChangeParams
} from '@material-ui/data-grid'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { colorType, IRouteProps, IEarning, IPaginationData } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{}>

const Earnings = (props: PropTypes) => {
  // styles
  const classes = styles()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'surname',
      headerName: 'Surname',
      flex: 1
    },
    {
      field: 'total_earned',
      headerName: 'Total Earned',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const totalEarned = params.getValue(params.id, 'total_earned')

        if (!totalEarned) return 0
        return `€ ${(+totalEarned / 100).toFixed(2)}`
      }
    },
    {
      field: 'total_fee',
      headerName: 'Total Fee',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const totalFee = params.getValue(params.id, 'total_fee')

        if (!totalFee) return 0
        return `€ ${(+totalFee / 100).toFixed(2)}`
      }
    },
    {
      field: 'amount_payable',
      headerName: 'Payable Amount',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const amountPayable = params.getValue(params.id, 'amount_payable')

        if (!amountPayable) return 0
        return `€ ${(+amountPayable / 100).toFixed(2)}`
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridValueFormatterParams) => {
        const status: GridCellValue = params.getValue(params.id, 'status')
        let color: colorType = 'default'

        if (status === 'PAID') color = 'primary'

        return <Chip color={color} label={status} />
      }
    }
  ]

  // constants
  const PAGE_LIMIT = 20

  // local state
  const [earnings, setEarnings] = useState<IEarning[]>([])
  const [paginationData, setPaginationData] = useState<IPaginationData>({
    total: 0,
    page: 1
  })
  const [filterDate, setFilterDate] = useState({
    m: new Date().getMonth() + 1,
    y: new Date().getFullYear()
  })

  const fetchEarnings = useCallback(async () => {
    const fetchedEarnings = await utils.REQ(
      'get',
      `${utils.EP.EARNINGS}?limit=${PAGE_LIMIT}&m=${filterDate.m}&y=${filterDate.y}`
    )

    setEarnings(fetchedEarnings.users)
    setPaginationData({ page: fetchedEarnings.page, total: fetchedEarnings.total })
  }, [filterDate])

  useEffect(() => {
    fetchEarnings()
  }, [fetchEarnings])

  const handleDateChange = (value: string, field: 'm' | 'y') => {
    setFilterDate((currState) => ({ ...currState, [field]: value }))
  }

  const months = useMemo(() => {
    return {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    } as any
  }, [])

  const years = useMemo(() => {
    const startYear: number = 2021
    const currentYear: number = new Date().getFullYear()

    const yearsArr = []

    for (let year = startYear; year <= currentYear; year++) {
      yearsArr.push(year)
    }

    return yearsArr
  }, [])

  return (
    <Layout>
      <Grid justify="space-between" spacing={2} container>
        <Grid item md={8}>
          <TextField variant="outlined" fullWidth label="Search earnings..." />
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="month"
              variant="outlined"
              value={filterDate.m}
              onChange={(e) => handleDateChange(e.target.value as string, 'm')}
            >
              {Array.from({ length: 12 }, (_, i) => {
                return (
                  <MenuItem key={i} value={i + 1}>
                    {months[i + 1]}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="year"
              variant="outlined"
              fullWidth
              value={filterDate.y}
              onChange={(e) => handleDateChange(e.target.value as string, 'y')}
            >
              {years.map((y: number) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={earnings.map((earning: IEarning) => ({
            ...earning,
            id: earning._id
          }))}
          columns={columns}
          pageSize={PAGE_LIMIT}
          rowCount={paginationData.total}
          paginationMode="server"
          disableSelectionOnClick
          onPageChange={(params: GridPageChangeParams) => {
            setPaginationData((currState: IPaginationData) => ({
              ...currState,
              page: params.page + 1
            }))
          }}
          onRowClick={(param: GridRowParams) =>
            props.history.push(`/earning/${param.id}`)
          }
        />
      </div>
    </Layout>
  )
}

export default withRouter(Earnings)
