import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  cardContent: {
    padding: theme.spacing(5)
  },
  chip: {
    marginRight: 10,
    marginBottom: 10
  },
  appButton: {
    height: '100%',
    textDecoration: 'none'
  },
  tableContainer: {
    width: '100%',
    height: 'calc(100vh - 230px)'
  }
}))
