import { Link, withRouter } from 'react-router-dom'
import { useForm } from 'react-hook-form'

// logo
import Logo from 'assets/canguru-logo.png'

// ui elements
import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// components
import { AppButton } from 'components'

// global state
import { useApp } from 'providers/AppContextProvider'

// utils
import utils from 'utils'

// types
import { ILoginDetails, ILoginResult } from 'types'

// styles
import styles from './styles'

const Login = () => {
  // styles
  const classes = styles()

  // global state
  const { setUser } = useApp()

  // react hook form
  const { register, handleSubmit } = useForm()

  const onLogin = async (values: ILoginDetails): Promise<void> => {
    const loginResult: ILoginResult = await utils.REQ('post', utils.EP.AUTH_LOGIN, values)

    // storing the user in global storage
    setUser({ ...loginResult.payload })

    // storing the user and token in localStorage to persist across refreshes
    localStorage.setItem('user', JSON.stringify(loginResult.payload))
    localStorage.setItem('token', loginResult.token)

    // sending the user to dashboard
    window.location.href = '/'
  }

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={Logo} width={80} alt="Canguru Logo" />
        </Avatar>
        <Typography className={classes.title} component="h1" variant="h5">
          Canguru
        </Typography>

        <form onSubmit={handleSubmit(onLogin)} className={classes.form} noValidate>
          <TextField
            {...register('email')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            autoComplete="email"
            autoFocus
          />
          <TextField
            {...register('password')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
          />

          <Box height={20} />

          <AppButton>Login</AppButton>

          <Box height={20} />

          <Link className={classes.link} to="/forgot-password">
            <Typography align="center" variant="body2">
              Forgot password?
            </Typography>
          </Link>
        </form>
      </div>
    </Container>
  )
}

export default withRouter(Login)
