import { useState, useEffect, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

// HOCs
import Layout from 'HOCs/Layout'

// components
import { AppButton } from '../../components'

// types
import { IRouteProps, IEarningData } from 'types'

// utils
import utils from 'utils'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{ id: string }>

const EarningDetail = (props: PropTypes) => {
  // styles
  const classes = styles()

  // constants
  const guruId = props.match.params.id
  const EARNING_STATUS = {
    PAID: 'PAID',
    UNPAID: 'UNPAID'
  }

  // local state
  const [earningData, setEarningData] = useState<IEarningData | null>(null)
  const [earningStatus, setEarningStatus] = useState<string>(EARNING_STATUS.UNPAID)
  const [filterDate, setFilterDate] = useState({
    m: new Date().getMonth() + 1,
    y: new Date().getFullYear()
  })

  const fetchEarningDetails = useCallback(async () => {
    const earningData = await utils.REQ(
      'get',
      `${utils.EP.EARNINGS}/${guruId}?m=${filterDate.m}&y=${filterDate.y}`
    )

    if (earningData) {
      setEarningData(earningData)
      setEarningStatus(earningData.status)
    }
  }, [guruId, filterDate])

  useEffect(() => {
    fetchEarningDetails()
  }, [fetchEarningDetails])

  const months = useMemo(() => {
    return {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    } as any
  }, [])

  const years = useMemo(() => {
    const startYear: number = 2021
    const currentYear: number = new Date().getFullYear()

    const yearsArr = []

    for (let year = startYear; year <= currentYear; year++) {
      yearsArr.push(year)
    }

    return yearsArr
  }, [])

  const handleDateChange = (value: string, field: 'm' | 'y') => {
    setFilterDate((currState) => ({ ...currState, [field]: value }))
  }

  const onEarningStatusUpdate = async () => {
    await utils.REQ('post', utils.EP.EARNINGS_STATUS_UPDATE, {
      guru_id: guruId,
      status: earningStatus
    })
  }

  const isLastMonthFromSelectedDate = useMemo(() => {
    const currentDate = dayjs()
    const prevMonthDate = dayjs(`${filterDate.y}-${filterDate.m}-01`)

    return currentDate.get('month') - 1 === prevMonthDate.get('month')
  }, [filterDate.m, filterDate.y])

  return (
    <Layout>
      {earningData ? (
        <>
          <Grid justify="space-between" spacing={2} container>
            <Grid item md={6}></Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  labelId="month"
                  variant="outlined"
                  value={filterDate.m}
                  onChange={(e) => handleDateChange(e.target.value as string, 'm')}
                >
                  {Array.from({ length: 12 }, (_, i) => {
                    return (
                      <MenuItem key={i} value={i + 1}>
                        {months[i + 1]}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  labelId="year"
                  variant="outlined"
                  fullWidth
                  value={filterDate.y}
                  onChange={(e) => handleDateChange(e.target.value as string, 'y')}
                >
                  {years.map((y: number) => (
                    <MenuItem key={y} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box height={25} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={4} item md={12}>
                    <Grid item md={4}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Name
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.name ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Surname
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.surname ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Payable Amount
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.amount_payable
                          ? (earningData.amount_payable / 100).toFixed(2)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Status
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          labelId="month"
                          variant="outlined"
                          margin="dense"
                          disabled={!isLastMonthFromSelectedDate}
                          value={earningStatus}
                          onChange={(e) => setEarningStatus(e.target.value as string)}
                        >
                          <MenuItem value={EARNING_STATUS.PAID}>PAID</MenuItem>
                          <MenuItem value={EARNING_STATUS.UNPAID}>UNPAID</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Total Earned
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.total_earned
                          ? (earningData.total_earned / 100).toFixed(2)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Total Fee
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.total_fee
                          ? (earningData.total_fee / 100).toFixed(2)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6}>
              <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={4} item md={12}>
                    <Grid item md={12}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Bank Name
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.bank_name ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Beneficiary Name
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.beneficiary_name ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        IBAN
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {earningData.iban ?? 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box height={50} />

          <Grid container>
            <Grid style={{ textAlign: 'center' }} item md={12}>
              <AppButton
                disabled={!isLastMonthFromSelectedDate}
                onClick={onEarningStatusUpdate}
                fullWidth={false}
              >
                UPDATE STATUS
              </AppButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Layout>
  )
}

export default EarningDetail
