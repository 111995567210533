import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  tableContainer: {
    width: '100%',
    height: 'calc(100vh - 230px)'
  },
  profilePictureIcon: {
    borderRadius: '50px',
    minWidth: '20px',
    overflow: 'hidden',
    marginRight: '10px'
  }
}))
