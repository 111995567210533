import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  cardContent: {
    padding: theme.spacing(5)
  },
  profilePicture: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    border: `5px solid ${theme.palette.primary.main}`
  }
}))
