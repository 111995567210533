import { useMemo, useState } from 'react'

// ui elements
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// components
import {
  FeedbackKeywordSuggestions,
  FeedbackTopicSuggestions,
  FeedbackReportedProblems
} from '../../components'

// HOCs
import Layout from 'HOCs/Layout'

// icons
import SpellcheckIcon from '@material-ui/icons/Spellcheck'
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

// styles
import styles from './styles'

const Feedback = () => {
  // styles
  const classes = styles()

  // local state
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Layout>
      <Paper className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={(_, newTab) => setSelectedTab(newTab)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab
            className={classes.tab}
            icon={<SpellcheckIcon />}
            label="Suggested Keywords"
          />
          <Tab
            className={classes.tab}
            icon={<AssignmentIcon />}
            label="Suggested Topics"
          />
          <Tab className={classes.tab} icon={<ErrorIcon />} label="Reported Problems" />
        </Tabs>
      </Paper>

      <TabPanel currentTab={selectedTab} />
    </Layout>
  )
}

export default Feedback

const TabPanel = ({ currentTab }: { currentTab: number }) => {
  const TabViews = useMemo(
    () => [
      FeedbackKeywordSuggestions,
      FeedbackTopicSuggestions,
      FeedbackReportedProblems
    ],
    []
  )
  const CurrentView = TabViews[currentTab]

  if (!CurrentView) return null
  return <CurrentView />
}
