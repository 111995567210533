import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  Fragment,
  useMemo,
  useRef
} from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import {
  DataGrid,
  GridColDef,
  GridPageChangeParams,
  GridValueFormatterParams
} from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// components
import { TopicModal, AppButton } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// icons
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

// utils
import utils from 'utils'

// types
import {
  IRouteProps,
  ITopic,
  ITopicModal,
  ITopicModalValues,
  IPaginationData
} from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{}>

const Topics = (props: PropTypes) => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 20
  const DEBOUNCE_DURATION: number = 500

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: 1,
      renderCell: (params: GridValueFormatterParams) => {
        const nameObj = params.row.name

        if (!nameObj) return 'N/A'

        const names = Object.keys(nameObj).map((item) => {
          return { lang: item, value: nameObj[item] }
        })

        return names.map((name, i) => {
          return (
            <Fragment key={name.value + Math.random() * 100}>
              <strong>{name.lang.toUpperCase()}: &nbsp;</strong>
              <span>{name.value}</span>
              {i !== names.length - 1 && <span>, &nbsp;</span>}
            </Fragment>
          )
        })
      }
    },
    {
      field: 'guruCount',
      headerName: 'Guru',
      sortable: false,
      flex: 0.2
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      sortable: false,
      flex: 0.2
    },
    {
      field: '_id',
      headerName: 'Actions',
      flex: 0.2,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)

        const topicData: ITopic = {
          _id: getValue('_id'),
          name: getValue('name'),
          created_at: getValue('created_at')
        }

        return (
          <>
            <IconButton
              onClick={() => onTopicEdit(topicData)}
              color="default"
              component="span"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => onTopicDelete(topicData)}
              color="default"
              component="span"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    }
  ]

  // local state
  const [topics, setTopics] = useState<ITopic[]>([])
  const [topicSearchQuery, setTopicSearchQuery] = useState<string>('')
  const [topicPaginationData, setTopicPaginationData] = useState<IPaginationData>({
    total: 0,
    page: 1
  })
  const [sortBy, setSortBy] = useState<string>('created_at')
  const [topicEditData, setTopicEditData] = useState<ITopic | null>(null)
  const [topicModal, setTopicModal] = useState<ITopicModal>({
    open: false,
    mode: 'Add'
  })
  const [searchTimeoutId, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null)

  // refs
  const searchRef = useRef('')
  const pageRef = useRef(1)

  const sortingOptions = useMemo(() => {
    return [
      { name: 'Alphabetical ASC', value: 'name.it' },
      { name: 'Alphabetical DESC', value: '-name.it' },
      { name: 'Created ASC', value: 'created_at' },
      { name: 'Created DESC', value: '-created_at' }
    ]
  }, [])

  const fetchTopics = useCallback(
    async (page?: number) => {
      const topicsData = await utils.REQ(
        'get',
        `${utils.EP.TOPICS}?sort=${sortBy}&search=${
          searchRef.current
        }&field=name.it&limit=${RESULT_LIMIT}&page=${page ?? pageRef.current}`
      )

      // setting current page number
      pageRef.current = topicsData.page

      setTopics(topicsData.data)
      setTopicPaginationData({ page: topicsData.page, total: topicsData.total })
    },
    [sortBy]
  )

  useEffect(() => {
    fetchTopics()
  }, [fetchTopics])

  const toggleTopicModal = (mode: 'Add' | 'Edit' = 'Add'): void => {
    setTopicModal((currState: ITopicModal) => ({ mode, open: !currState.open }))
  }

  const onTopicAdd = (): void => {
    setTopicEditData(null)
    toggleTopicModal()
  }

  const onTopicEdit = (topicData: ITopic): void => {
    setTopicEditData(topicData)
    toggleTopicModal('Edit')
  }

  const onTopicDelete = async (topicData: ITopic): Promise<void> => {
    await utils.REQ('delete', `${utils.EP.TOPICS_DELETE}/${topicData._id}`)
    await fetchTopics()
  }

  const onAddEditTopic = async (
    values: ITopicModalValues,
    mode: 'Add' | 'Edit'
  ): Promise<void> => {
    if (mode === 'Add') {
      await utils.REQ('post', utils.EP.TOPICS_CREATE, values)
    } else {
      await utils.REQ('put', utils.EP.TOPICS_UPDATE, {
        id: topicEditData?._id,
        ...values
      })
    }

    await fetchTopics(1)
  }

  const onTopicQueryChange = (value: string) => {
    setTopicSearchQuery(value)

    // using ref so it does not re-run fetchTopics useCallback
    searchRef.current = value

    if (searchTimeoutId) clearTimeout(searchTimeoutId)

    // setting page to 1 on search
    pageRef.current = 1

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      if (value.trim().length > 2) fetchTopics()

      // if no query, fetch the topics from page 1
      if (value.trim().length === 0) fetchTopics(1)
    }, DEBOUNCE_DURATION)

    setSearchTimeoutId(timeoutId)
  }

  const onCellClick = (field: string, id: string) => {
    if (field !== 'name') return

    props.history.push(`/topic/${id}`)
  }

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <TextField
            value={topicSearchQuery}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onTopicQueryChange(e.target.value)
            }
            variant="outlined"
            fullWidth
            label="Search topics..."
          />
        </Grid>
        <Grid item xs={2}>
          <AppButton onClick={() => onTopicAdd()} className={classes.appButton}>
            Add topic
          </AppButton>
        </Grid>

        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="year"
              variant="outlined"
              fullWidth
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as string)}
            >
              {sortingOptions.map((sortingOption: any) => (
                <MenuItem key={sortingOption.name} value={sortingOption.value}>
                  {sortingOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={topics.map((topic: ITopic) => ({
            ...topic,
            id: topic._id,
            created_at: utils.helpers.formatDate(topic.created_at)
          }))}
          columns={columns}
          pageSize={RESULT_LIMIT}
          disableSelectionOnClick
          onCellClick={(cell) => onCellClick(cell.field, cell.row.id)}
          rowCount={topicPaginationData.total}
          paginationMode="server"
          onPageChange={(params: GridPageChangeParams) => {
            fetchTopics(params.page + 1)
          }}
        />
      </div>

      {/* MODAL */}
      <TopicModal
        onAddEditTopic={onAddEditTopic}
        data={topicEditData}
        topicModal={topicModal}
        toggleModal={toggleTopicModal}
      />
    </Layout>
  )
}

export default withRouter(Topics)
