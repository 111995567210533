import { useForm } from 'react-hook-form'

// logo
import Logo from 'assets/canguru-logo.png'

// ui elements
import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// components
import { AppButton } from 'components'

// utils
import utils from 'utils'

// types
import { IForgotPasswordDetails } from 'types'

// styles
import styles from './styles'

const ForgotPassword = () => {
  // styles
  const classes = styles()

  // react hook form
  const { register, handleSubmit } = useForm()

  const onForgotPassword = async (values: IForgotPasswordDetails): Promise<void> => {
    await utils.REQ('post', utils.EP.AUTH_FORGOT_PASSWORD, values)
  }

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={Logo} width={80} alt="Canguru Logo" />
        </Avatar>
        <Typography className={classes.title} component="h1" variant="h5">
          Canguru
        </Typography>

        <form
          onSubmit={handleSubmit(onForgotPassword)}
          className={classes.form}
          noValidate
        >
          <TextField
            {...register('email')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Box height={20} />

          <AppButton>Send Email</AppButton>
        </form>
      </div>
    </Container>
  )
}

export default ForgotPassword
