import { useState, ChangeEvent, useEffect } from 'react'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Autocomplete, {
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'

// components
import { AppButton } from 'components'

// hooks
import useDebounced from 'hooks/useDebounced'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { IRouteProps, ITopic } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{ id: string }>

const TopicGroupAdd = (props: PropTypes) => {
  // styles
  const classes = styles()

  // local state
  const [topicGroupName, setTopicGroupName] = useState<string>('')
  const [topicQuery, setTopicQuery] = useState<string>('')
  const [topics, setTopics] = useState<ITopic[]>([])
  const [topicChips, setTopicChips] = useState<ITopic[]>([])
  const [fieldErrors, setFieldErrors] = useState<{
    groupName: string | null
    topics: string | null
  }>({ groupName: null, topics: null })
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const fetchTopics = async (query: string): Promise<void> => {
    try {
      if (query.trim().length > 2) {
        const topicsData = await utils.REQ(
          'get',
          `${utils.EP.TOPICS}?field=name.it&search=${query}`
        )
        setTopics(topicsData.data)
      } else {
        setTopics([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const debouncedFetchTopics = useDebounced(fetchTopics)

  useEffect(() => {
    let message: string | null = ''

    if (topicGroupName.trim().length < 4) {
      message = 'Group Name must be at least 4 letters'
    } else {
      message = null
    }

    setFieldErrors((prevState) => ({
      ...prevState,
      groupName: message
    }))
  }, [topicGroupName])

  useEffect(() => {
    let message: string | null = ''

    if (topicChips.length === 0) {
      message = 'At least one topic must be provided'
    } else {
      message = null
    }

    setFieldErrors((prevState) => ({
      ...prevState,
      topics: message
    }))
  }, [topicChips])

  const onTopicSelect = (passedTopic: ITopic | null) => {
    if (!passedTopic) return

    const existingTopicIndex: number = topicChips.findIndex(
      (topic) => topic._id === passedTopic._id
    )

    if (existingTopicIndex === -1) {
      setTopicChips((prevState) => [...prevState, passedTopic])
    }
  }

  const onTopicDelete = (passedTopic: ITopic) => {
    const topicChipsCopy = [...topicChips]

    const topicIndex = topicChipsCopy.findIndex((topic) => topic._id === passedTopic._id)

    if (topicIndex !== -1) {
      topicChipsCopy.splice(topicIndex, 1)
      setTopicChips(topicChipsCopy)
    }
  }

  const onSave = async (): Promise<void> => {
    if (fieldErrors.groupName || fieldErrors.topics) {
      setShowErrors(true)
      return
    } else {
      setShowErrors(false)
    }

    const data = {
      name: topicGroupName,
      topics: topicChips.map((topic) => topic._id)
    }

    // sending tags data
    await utils.REQ('post', utils.EP.TOPIC_GROUPS_CREATE, data)

    // resetting data
    setTopicGroupName('')
    setTopicChips([])
  }

  return (
    <Layout>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body1" gutterBottom>
            ADD NEW TOPIC GROUP
          </Typography>

          <Box height={10} />

          <Divider />

          <Grid container direction="column">
            <Box height={30} />

            <Grid item xs={12}>
              <TextField
                value={topicGroupName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTopicGroupName(e.target.value)
                }}
                error={showErrors}
                helperText={showErrors && fieldErrors.groupName}
                variant="outlined"
                fullWidth
                label="Group Name"
              />
            </Grid>

            <Box height={20} />

            <Grid item xs={12}>
              <Autocomplete
                debug={true} // need this to override `open`
                options={topics}
                open={!!topics.length}
                getOptionLabel={(option: ITopic) => option.name['it']}
                getOptionSelected={(option: ITopic, value: ITopic) =>
                  option._id === value._id
                }
                blurOnSelect
                clearOnBlur
                onChange={(_, topic: ITopic | null) => onTopicSelect(topic)}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    value={topicQuery}
                    label={'Add topic to group'}
                    margin="normal"
                    size="medium"
                    onBlur={() => setTopics([])}
                    error={showErrors}
                    helperText={showErrors && fieldErrors.topics}
                    onChange={(
                      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      setTopicQuery(e.target.value)
                      debouncedFetchTopics(e.target.value as string)
                    }}
                  />
                )}
              />
            </Grid>

            <Box height={20} />

            <div>
              {/* TOPICS AS CHIPS */}
              {topicChips &&
                topicChips.map((topic: ITopic) => (
                  <Chip
                    className={classes.chip}
                    key={topic._id}
                    color="primary"
                    variant="outlined"
                    label={topic.name['it']}
                    onDelete={() => onTopicDelete(topic)}
                  />
                ))}
            </div>

            <Box height={30} />
            <AppButton onClick={onSave}>Save</AppButton>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default TopicGroupAdd
