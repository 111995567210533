import { useState, useEffect, useCallback } from 'react'

// ui elements
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import {
  DataGrid,
  GridColDef,
  GridPageChangeParams,
  GridValueFormatterParams
} from '@material-ui/data-grid'

// hooks
// import useDebounced from 'hooks/useDebounced'

// components
// import { AppButton, TagModal } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// icons
// import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

// types
import { IPaginationData, ILog } from 'types'

// styles
import styles from './styles'

const Logs = () => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 20

  const columns: GridColDef[] = [
    {
      field: 'severity',
      headerName: 'Severity',
      flex: 0.2,
      sortable: true,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        if (getValue('severity') === 'OK') {
          return (
            <Chip
              label={getValue('severity')}
              style={{ backgroundColor: '#4caf50', color: '#ffffff' }}
            />
          )
        } else if (getValue('severity') === 'ERROR') {
          return (
            <Chip
              label={getValue('severity')}
              style={{ backgroundColor: '#f44336', color: '#ffffff' }}
            />
          )
        } else {
          return (
            <Chip
              label={getValue('severity')}
              style={{ backgroundColor: '#ff9800', color: '#ffffff' }}
            />
          )
        }
      }
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 0.3,
      sortable: false
    },
    {
      field: 'user.role',
      headerName: 'User role',
      flex: 0.2,
      sortable: true,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        return <p>{getValue('user')['role']}</p>
      }
    },
    {
      field: 'user',
      headerName: 'User email',
      flex: 0.3,
      sortable: false,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)
        return (
          <>
            <p>{getValue('user')['email']}</p>
          </>
        )
      }
    },
    {
      field: 'created_at',
      headerName: 'Created on',
      sortable: false,
      flex: 0.2
    },
    {
      field: 'language',
      hide: true
    },
    {
      field: '_id',
      headerName: 'Actions',
      flex: 0.1,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)

        return (
          <>
            {/* <IconButton
              onClick={() => onEditTag(tagData)}
              color="default"
              component="span"
            >
              <EditIcon />
            </IconButton> */}
            <IconButton
              onClick={() => onDeleteTag(getValue('_id'))}
              color="default"
              component="span"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )
      }
    }
  ]

  // local state
  const [logs, setLogs] = useState<ILog[]>([])
  // const [searchQuery, setSearchQuery] = useState<string>('')
  const [paginationData, setPaginationData] = useState<IPaginationData>({
    total: 0,
    page: 1
  })

  const fetchLogs = useCallback(async (search: string = '', page: number = 1) => {
    const fetchedData = await utils.REQ(
      'get',
      `${utils.EP.LOGS}?search=${search}&limit=${RESULT_LIMIT}&page=${page}`
    )

    const fetchedLogs: ILog[] = fetchedData.data

    setLogs(fetchedLogs)
    setPaginationData({ page, total: fetchedData.total })
  }, [])

  useEffect(() => {
    fetchLogs()
  }, [fetchLogs])

  // fetch tags with debounce
  // const debouncedFetchTags = useDebounced(fetchLogs)

  // const onSearchQuery = (val: string) => {
  //   setSearchQuery(val)

  //   if (val.length > 2) debouncedFetchTags(val)
  //   else if (val.length === 0) fetchLogs()
  // }

  const onDeleteTag = async (id: string): Promise<void> => {
    await utils.REQ('delete', `${utils.EP.LOGS_DELETE}/${id}`)

    // refresh
    fetchLogs()
  }

  return (
    <Layout>
      {/* <Grid container spacing={5}>
         <Grid item xs={8}>
          <TextField
            variant="outlined"
            onChange={(e) => onSearchQuery(e.target.value)}
            fullWidth
            label="Search tags..."
          />
        </Grid>
        <Grid item xs={2}>
          <AppButton onClick={onAddTag} className={classes.appButton}>
            Add tag
          </AppButton>
        </Grid> 
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              labelId="year"
              variant="outlined"
              fullWidth
              value={selectedLanguage ?? ''}
              onChange={(e) => setSelectedLanguage(e.target.value as string)}
            >
              {languages.map((lang: ILang) => (
                <MenuItem key={lang._id} value={lang._id}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> 
      </Grid> */}

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={logs.map((log: ILog) => ({
            ...log,
            id: log._id,
            created_at: utils.helpers.formatDate(log.created_at as string, true)
          }))}
          paginationMode="server"
          columns={columns}
          pageSize={RESULT_LIMIT}
          rowCount={paginationData.total}
          disableSelectionOnClick
          page={paginationData.page - 1}
          onPageChange={(params: GridPageChangeParams) => fetchLogs('', params.page + 1)}
        />

        {/* MODAL */}
      </div>
    </Layout>
  )
}

export default Logs
