import { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import {
  DataGrid,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridValueFormatterParams
} from '@material-ui/data-grid'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { colorType, IRouteProps, IUser } from 'types'

// styles
import styles from './styles'

type PropTypes = IRouteProps<{}>

const DeleteRequests = (props: PropTypes) => {
  // styles
  const classes = styles()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.6
    },
    {
      field: 'surname',
      headerName: 'Surname',
      flex: 0.6
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.8
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.5,
      renderCell: (params: GridValueFormatterParams) => {
        const role: GridCellValue = params.getValue(params.id, 'role')
        let color: colorType = 'default'

        if (role === 'ROLE_GURU') color = 'primary'
        else if (role === 'ROLE_ADMIN') color = 'secondary'

        const roleLabel: string = (params.getValue(params.id, 'role') as string)
          .split('_')[1]
          .toUpperCase()

        return <Chip color={color} label={roleLabel} />
      }
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      flex: 0.6
    },
    {
      field: 'created_at',
      headerName: 'Date of Registration',
      sortable: false,
      flex: 0.5
    },
    {
      field: 'deletion_requested_at',
      headerName: 'Deletion Requested',
      sortable: false,
      flex: 0.6
    }
  ]

  // local state
  const [users, setUsers] = useState<IUser[]>([])
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [page, setPage] = useState(1)

  const fetchRequests = useCallback(async () => {
    const fetchedUsers = await utils.REQ(
      'get',
      `${utils.EP.DELETE_REQUESTS}?limit=20&field=surname&search=${search}&page=${page}`
    )
    setUsers(fetchedUsers.users)
    setTotal(fetchedUsers.total)
  }, [search, page])

  useEffect(() => {
    fetchRequests()
  }, [fetchRequests])

  return (
    <Layout>
      <TextField
        variant="outlined"
        fullWidth
        label="Search users..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={users.map((user: IUser) => ({
            ...user,
            id: user._id,
            name: user.name ?? 'N/A',
            surname: user.surname ?? 'N/A',
            created_at: utils.helpers.formatDate(user.created_at),
            deletion_requested_at: utils.helpers.formatDate(
              user.deletion_requested_at as string,
              true
            )
          }))}
          columns={columns}
          pageSize={20}
          pagination
          page={page - 1}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage.page + 1)
          }}
          disableSelectionOnClick
          onCellClick={(params: GridCellParams) => {
            const allowedCells = ['name', 'surname', 'role', 'mobile', 'created_at']
            if (allowedCells.includes(params.field)) {
              props.history.push(`/user/${params.id}`)
            }
          }}
        />
      </div>
    </Layout>
  )
}

export default withRouter(DeleteRequests)
