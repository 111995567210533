import { useState, useEffect, useCallback } from 'react'

// ui elements
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Typography from '@material-ui/core/Typography'
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'

// HOCs
import Layout from 'HOCs/Layout'

// types
import { ChartType, IChartData, IRouteProps, ITopic, ITransaction, IUser } from 'types'

// utils
import utils from 'utils'

// styles
import styles from './styles'
import {
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

type PropTypes = IRouteProps<{ id: string }>

const User = (props: PropTypes) => {
  // styles
  const classes = styles()

  // local state
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState<IUser | null>(null)
  const [transactions, setTransactions] = useState<IChartData[]>([])
  const [chartType, setChartType] = useState<ChartType>('Monthly')

  const fetchUser = useCallback(async (): Promise<void> => {
    const fetchedUser: IUser = await utils.REQ(
      'get',
      `${utils.EP.USER}/${props.match.params.id}`
    )

    console.log('here again')
    setUser(fetchedUser)
  }, [props.match.params.id])

  const fetchTransactions = useCallback(async (): Promise<void> => {
    // if role is not guru, don't fetch transactions
    if (user?.role !== 'ROLE_GURU') return

    // different URLs for different charts
    const chartURLs = {
      Daily: `${utils.EP.TRANSACTIONS}?guru_id=${user._id}`,
      Weekly: `${utils.EP.TRANSACTIONS}?guru_id=${
        user._id
      }&start=${utils.helpers.getLastWeek()}`,
      Monthly: `${utils.EP.TRANSACTIONS}?guru_id=${user._id}`
    }

    // fetching transactions according to chart type
    const fetchedTransactions = await utils.REQ('get', chartURLs[chartType])
    const transactionData = fetchedTransactions.transactions

    setTransactions(
      transactionData
        .filter((transaction: ITransaction) => transaction.amount)
        .map((transaction: ITransaction) => ({
          xaxis: utils.helpers.formatDate(transaction.created_at),
          yaxis: transaction.amount / 100
        }))
    )
  }, [user?._id, user?.role, chartType])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])
  useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions])

  const changeChartType = (type: ChartType) => setChartType(type)

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = useCallback(async () => {
    await utils.REQ('delete', `${utils.EP.USER}/${props.match.params.id}`)
    setOpen(false)
    props.history.goBack()
  }, [props.match.params.id, props.history])

  return (
    <Layout>
      {user ? (
        <>
          <Card className={classes.root}>
            <CardContent className={classes.cardContent}>
              <Grid container justify="center">
                <Grid item>
                  <Avatar
                    className={classes.profilePicture}
                    alt={user.name}
                    src={user.profile_picture}
                  />
                  <Box height={50} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={user.role === 'ROLE_GURU' ? 9 : 12}>
                  <Grid container spacing={4}>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Name
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {user.name ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Surname
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {user.surname ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Mobile
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {user.mobile ?? 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Country
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {user.country ?? 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Role
                      </Typography>
                      <Typography variant="h6" component="h2">
                        <Chip
                          color="primary"
                          label={user.role.split('_')[1].toUpperCase()}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Joined
                      </Typography>
                      <Typography variant="h6" component="h2">
                        {utils.helpers.formatDate(user.created_at)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {user.role === 'ROLE_GURU' && (
                    <Grid container spacing={4}>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Card Brand
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {user.card_brand ?? 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Last 4 digits
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {user.card_last4 ?? 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Charge per minute
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {user.charge_per_minute
                            ? Number(user.charge_per_minute).toFixed(2)
                            : 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {user.role === 'ROLE_GURU' && (
                  <Grid item xs={3}>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Topics
                      </Typography>
                      <List>
                        {user.topics?.length ? (
                          user.topics.map((topic: ITopic) => {
                            if (!topic) return null
                            return (
                              <Typography key={topic._id} variant="body1">
                                <strong>EN: </strong> {topic.name['en']},{' '}
                                <strong>IT: </strong> {topic.name['it']}
                              </Typography>
                            )
                          })
                        ) : (
                          <Typography variant="body1">No topics added</Typography>
                        )}
                      </List>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            {user.deletion_requested_at ? (
              <>
                <CardActions>
                  <Button color="secondary" onClick={() => setOpen(true)}>
                    Delete
                  </Button>
                </CardActions>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Are you sure you want to delete this user?'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Warning: if you delete this user. You'll not be able to retrieve his
                      data. Make sure to note his account details and earnings.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : null}
          </Card>

          {user.role === 'ROLE_GURU' && transactions && (
            <>
              <Box height={50} />

              <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ButtonGroup color="primary">
                        <Button onClick={() => changeChartType('Daily')}>Daily</Button>
                        <Button onClick={() => changeChartType('Weekly')}>Weekly</Button>
                        <Button onClick={() => changeChartType('Monthly')}>
                          Monthly
                        </Button>
                      </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <Chart data={transactions}>
                        <ArgumentAxis />
                        <ValueAxis />

                        <BarSeries valueField="yaxis" argumentField="xaxis" />
                        <Title text={`${chartType} Income`} />
                        <Animation />
                      </Chart>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Layout>
  )
}

export default User
