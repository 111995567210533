import React, { useEffect, ChangeEvent, useCallback, useState } from 'react'

// ui elements
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

// types
import {
  ILang,
  ITopic,
  ITopicModal,
  ITopicModalValues,
  ITopicNameErrors,
  ITopicNames
} from 'types'

// utils
import utils from 'utils'

type PropTypes = {
  topicModal: ITopicModal
  toggleModal: (mode: 'Add' | 'Edit') => void
  data: ITopic | null
  onAddEditTopic: (values: ITopicModalValues, mode: 'Add' | 'Edit') => void
}

const TopicModal = ({ topicModal, toggleModal, onAddEditTopic, data }: PropTypes) => {
  // local state
  const [topicNames, setTopicNames] = useState<ITopicNames>({})
  const [languages, setLanguages] = useState<ILang[]>([])
  const [topicNameErrors, setTopicNameErrors] = useState<ITopicNameErrors>({})

  const fetchLanguages = useCallback(async () => {
    const fetchedLangs: ILang[] = (await utils.REQ('get', `${utils.EP.LANGUAGES}`)).data
    setLanguages(fetchedLangs)

    if (data) {
      setTopicNames(data.name)
      setTopicNameErrors({})
      return
    }

    const topicNamesFromLangs: ITopicNames = {}

    fetchedLangs.forEach((lang: ILang) => {
      topicNamesFromLangs[lang.short_name] = ''
    })

    setTopicNames(topicNamesFromLangs)
  }, [data])

  useEffect(() => {
    fetchLanguages()
  }, [fetchLanguages])

  const onTopicNameChange = (value: string, lang: ILang) => {
    let message = ''

    if (!isNaN(Number(value))) {
      message = 'Topic should not be a number'
    } else if (value.length < 2) {
      message = 'Topic should be at least 2 characters'
    } else {
      message = ''
    }

    setTopicNameErrors((currState: ITopicNameErrors) => ({
      ...currState,
      [lang.short_name]: message
    }))

    setTopicNames((currState: ITopicNames) => ({
      ...currState,
      [lang.short_name]: value
    }))
  }

  const onModalClose = () => {
    toggleModal('Add')

    const topicNamesFromLangs: ITopicNames = {}

    languages.forEach((lang: ILang) => {
      topicNamesFromLangs[lang.short_name] = ''
    })

    setTopicNames(topicNamesFromLangs)
    setTopicNameErrors({})
  }

  const addOrEditTopic = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const isTopicErrors: boolean = Object.keys(topicNameErrors).some((topicShortName) => {
      return !!topicNameErrors[topicShortName]
    })

    // if errors are present, don't make the request
    if (isTopicErrors) return

    onAddEditTopic({ name: topicNames }, topicModal.mode)

    // closing the modal
    onModalClose()
  }

  return (
    <Dialog fullWidth open={topicModal.open} onClose={onModalClose}>
      <DialogTitle>{topicModal.mode} Topic</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the name of the topic</DialogContentText>

        <form onSubmit={addOrEditTopic} noValidate>
          {languages.map((lang: ILang) => {
            return (
              <TextField
                key={lang._id}
                value={topicNames[lang.short_name]}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onTopicNameChange(e.target.value, lang)
                }
                variant="outlined"
                required
                margin="normal"
                label={`Name of topic in ${lang.name}`}
                fullWidth
                autoComplete="off"
                error={!!topicNameErrors[lang.short_name]}
                helperText={topicNameErrors[lang.short_name]}
              />
            )
          })}

          <Box height={20} />

          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            disableElevation
            color="primary"
          >
            {topicModal.mode} topic
          </Button>

          <Box height={10} />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default TopicModal
