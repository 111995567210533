import { useState, useEffect, useCallback, useRef, ChangeEvent, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { ILang, IRouteProps, ITopic } from 'types'

// styles
import styles from './styles'
import { Divider } from '@material-ui/core'

type PropTypes = IRouteProps<{}>

const TopicSuggestions = (props: PropTypes) => {
  // styles
  const classes = styles()

  // constants
  const DEBOUNCE_DURATION: number = 500

  // local state
  const [topicSuggestions, setTopicSuggestions] = useState<ITopic[]>([])
  const [languages, setLanguages] = useState<ILang[]>([])
  const [selectedLang, setSelectedLang] = useState<string>('loading')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchTimeoutId, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null)

  // refs
  const searchRef = useRef('')

  const fetchLanguages = useCallback(async () => {
    const languageData = (await utils.REQ('get', `${utils.EP.LANGUAGES}`)).data

    if (languageData.length === 0) return

    setSelectedLang(languageData[0]._id)
    setLanguages(languageData)
  }, [])

  const onTopicSuggestionsSearch = (value: string) => {
    setSearchQuery(value)
    searchRef.current = value
    setIsLoading(true)

    if (searchTimeoutId) clearTimeout(searchTimeoutId)

    const timeoutId: NodeJS.Timeout = setTimeout(async () => {
      if (value.trim().length > 2) {
        await fetchTopicSuggestions()
      }

      // if no query, fetch the topics from page 1
      if (value.trim().length <= 2) {
        setIsLoading(false)
        setTopicSuggestions([])
      }
    }, DEBOUNCE_DURATION)

    setSearchTimeoutId(timeoutId)
  }

  const fetchTopicSuggestions = useCallback(async (): Promise<void> => {
    const suggestionData = await utils.REQ(
      'get',
      `${
        utils.EP.TOPIC_SUGGESTIONS
      }?language=${selectedLang}&question=${searchRef.current.trim()}`
    )

    setTopicSuggestions(suggestionData)
    setIsLoading(false)
  }, [selectedLang])

  useEffect(() => {
    fetchLanguages()
  }, [fetchLanguages])

  useEffect(() => {
    if (searchRef.current.trim().length > 2) {
      fetchTopicSuggestions()
    }
  }, [fetchTopicSuggestions])

  const currentLang = useMemo(() => {
    if (selectedLang && selectedLang !== 'loading' && languages.length) {
      const lang = languages.find((lang) => lang._id === selectedLang)

      if (!lang) return 'en'
      else return lang.short_name
    }

    return 'en'
  }, [selectedLang, languages])

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={9}>
          <TextField
            value={searchQuery}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onTopicSuggestionsSearch(e.target.value)
            }
            variant="outlined"
            fullWidth
            label="Search topic suggestions..."
          />
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth>
            <Select
              value={selectedLang}
              onChange={(e) => setSelectedLang(e.target.value as string)}
              labelId="year"
              variant="outlined"
              fullWidth
            >
              {languages.length > 0 ? (
                languages.map((language) => (
                  <MenuItem key={language._id} value={language._id}>
                    {language.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="loading">Loading...</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box height={30} />

      <div className={classes.tableContainer}>
        {isLoading && (
          <Box height={40}>
            <Typography variant="body2">Loading...</Typography>
          </Box>
        )}

        {topicSuggestions && topicSuggestions.length
          ? topicSuggestions.map((topic: ITopic) => {
              return (
                <Card key={topic._id} className={classes.cardContainer}>
                  <Box>
                    <Typography className={classes.fontBold} variant="body1">
                      {topic.name[currentLang]} ({topic.tags?.length}{' '}
                      {topic.tags && topic.tags.length > 1 ? 'tags' : 'tag'})
                    </Typography>

                    <Box height={10} />

                    {topic.tags?.map((tag) => (
                      <Chip
                        className={classes.chip}
                        key={tag as unknown as string}
                        color="primary"
                        variant="outlined"
                        label={tag}
                      />
                    ))}
                  </Box>

                  <Box height={20} />
                  <Divider />
                  <Box height={20} />

                  <Box>
                    <Typography className={classes.fontBold}>
                      Matched Keywords{' '}
                    </Typography>

                    <Box height={10} />

                    {topic.matches?.map((matchedKeyword: string) => (
                      <Chip
                        className={classes.chip}
                        key={matchedKeyword}
                        color="primary"
                        variant="outlined"
                        label={matchedKeyword}
                      />
                    ))}
                  </Box>
                </Card>
              )
            })
          : topicSuggestions &&
            topicSuggestions.length === 0 &&
            !isLoading && (
              <Box
                display={'flex'}
                height={200}
                justifyContent="center"
                alignItems={'center'}
              >
                <Typography variant="h6">
                  {searchQuery.length > 2
                    ? 'No topic suggestions found...'
                    : 'Enter keywords to search topics...'}
                </Typography>
              </Box>
            )}
      </div>
    </Layout>
  )
}

export default withRouter(TopicSuggestions)
