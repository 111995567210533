import { useEffect, useState, useCallback } from 'react'

const useDebounced = (callback: Function, debounceDuration: number = 500) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      timeoutId && clearTimeout(timeoutId)
    }
  }, [timeoutId])

  return useCallback(
    (query: string | Object) => {
      timeoutId && clearTimeout(timeoutId)

      const id: NodeJS.Timeout = setTimeout(() => callback(query), debounceDuration)
      setTimeoutId(id)
    },
    [timeoutId, callback, debounceDuration]
  )
}

export default useDebounced
