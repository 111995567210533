import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import {
  DataGrid,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridValueFormatterParams
} from '@material-ui/data-grid'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

// icons
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import PersonAdd from '@material-ui/icons/PersonAdd'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { colorType, IRouteProps, ITopic, IUser } from 'types'

// styles
import styles from './styles'
import Autocomplete, {
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'
import useDebounced from 'hooks/useDebounced'

type PropTypes = IRouteProps<{}>

const Users = (props: PropTypes) => {
  // styles
  const classes = styles()

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.6
    },
    {
      field: 'surname',
      headerName: 'Surname',
      flex: 0.6
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      flex: 0.6
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.9
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.3,
      renderCell: (params: GridValueFormatterParams) => {
        const role: GridCellValue = params.getValue(params.id, 'role')
        let color: colorType = 'default'

        if (role === 'ROLE_GURU') color = 'primary'
        else if (role === 'ROLE_ADMIN') color = 'secondary'

        const roleLabel: string = (params.getValue(params.id, 'role') as string)
          .split('_')[1]
          .toUpperCase()

        return <Chip color={color} label={roleLabel} />
      }
    },
    {
      field: 'device_type',
      headerName: 'Device',
      flex: 0.6
    },
    {
      field: 'created_at',
      headerName: 'Registration',
      sortable: false,
      flex: 0.5
    },
    {
      field: '_id',
      headerName: 'Tester',
      flex: 0.2,
      sortable: false,
      renderCell: (params: GridValueFormatterParams) => {
        const getValue = (field: string): any => params.getValue(params.id, field)

        const userData = {
          _id: getValue('_id'),
          name: getValue('name'),
          surname: getValue('surname'),
          mobile: getValue('mobile'),
          role: getValue('role'),
          tester: getValue('tester'),
          created_at: getValue('created_at')
        }

        return (
          <IconButton
            onClick={() => onToggleTester(userData._id, !!userData.tester)}
            title={userData.tester ? 'Remove tester access' : 'Add tester access'}
            color="default"
            component="span"
          >
            {userData.tester ? <NotInterestedIcon /> : <PersonAdd />}
          </IconButton>
        )
      }
    }
  ]

  // local state
  const [users, setUsers] = useState<IUser[]>([])
  const [search, setSearch] = useState('')
  const [topic, setTopic] = useState<ITopic | null>(null)
  const [userRole, setUserRole] = useState<string>('All')
  const [topicQuery, setTopicQuery] = useState<string>('')
  const [topics, setTopics] = useState<ITopic[]>([])
  const [total, setTotal] = useState(1)
  const [page, setPage] = useState(1)

  const fetchUsers = useCallback(async () => {
    const fetchedUsers = await utils.REQ(
      'get',
      `${utils.EP.USERS}?limit=20&field=surname&search=${search}&page=${page}${
        userRole !== 'All' ? '&role=' + userRole : ''
      }${topic && userRole == 'ROLE_GURU' ? '&topic=' + topic._id : ''}`
    )
    setUsers(fetchedUsers.users)
    setTotal(fetchedUsers.total)
  }, [search, page, topic, userRole])

  // fetch topics
  const fetchTopics = async (query: string): Promise<void> => {
    try {
      if (query.trim().length > 2) {
        const topicsData = await utils.REQ(
          'get',
          `${utils.EP.TOPICS}?field=name.it&search=${query}`
        )
        setTopics(topicsData.data)
      } else {
        setTopics([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const debouncedFetchTopics = useDebounced(fetchTopics)

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const onToggleTester = async (userId: string, isTester: boolean) => {
    await utils.REQ('put', utils.EP.USER_TESTER, { id: userId, tester: !isTester })
    fetchUsers()
  }

  const handleUserRoleChange = (value: string) => {
    if (value === 'ROLE_GURU') setTopic(null)
    setUserRole(value)
  }

  const onTopicSelect = (passedTopic: ITopic | null) => {
    setTopic(passedTopic)
  }

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Search users..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">User Role</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              value={userRole}
              label="User Role"
              onChange={(e) => handleUserRoleChange(e.target.value as string)}
            >
              <MenuItem value={'All'} selected>
                All
              </MenuItem>
              <MenuItem value={'ROLE_GUEST'}>GUEST</MenuItem>
              <MenuItem value={'ROLE_GURU'}>GURU</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Box height={20} />

        {userRole === 'ROLE_GURU' ? (
          <Grid item xs={12}>
            <Autocomplete
              debug={true} // need this to override `open`
              options={topics}
              open={!!topics.length}
              getOptionLabel={(option: ITopic) => option.name['it']}
              getOptionSelected={(option: ITopic, value: ITopic) =>
                option._id === value._id
              }
              blurOnSelect
              clearOnBlur
              onChange={(_, topic: ITopic | null) => onTopicSelect(topic)}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  value={topicQuery}
                  label={'Select topic'}
                  margin="normal"
                  size="medium"
                  onBlur={() => setTopics([])}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setTopicQuery(e.target.value)
                    debouncedFetchTopics(e.target.value as string)
                  }}
                />
              )}
            />
          </Grid>
        ) : null}
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        <DataGrid
          rows={users.map((user: IUser) => ({
            ...user,
            id: user._id,
            name: user.name ?? 'N/A',
            surname: user.surname ?? 'N/A',
            created_at: utils.helpers.formatDate(user.created_at)
          }))}
          columns={columns}
          pageSize={20}
          pagination
          page={page - 1}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            console.log('page changed')
            setPage(newPage.page + 1)
          }}
          disableSelectionOnClick
          onCellClick={(params: GridCellParams) => {
            const allowedCells = ['name', 'surname', 'role', 'mobile', 'created_at']
            if (allowedCells.includes(params.field)) {
              props.history.push(`/user/${params.id}`)
            }
          }}
        />
      </div>
    </Layout>
  )
}

export default withRouter(Users)
