import { useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'

// logo
import Logo from 'assets/canguru-logo.png'

// ui elements
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// icons
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExtensionIcon from '@material-ui/icons/Extension'
import LayersIcon from '@material-ui/icons/Layers'
import AccountCircle from '@material-ui/icons/AccountCircle'
import GroupIcon from '@material-ui/icons/Group'
import TranslateIcon from '@material-ui/icons/Translate'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import FeedbackIcon from '@material-ui/icons/Feedback'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import ReportIcon from '@material-ui/icons/Report'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'

// global state
import { useApp } from 'providers/AppContextProvider'

// styles
import styles from './styles'

const Navbar = () => {
  // styles
  const classes = styles()

  const location = useLocation()
  // const history = useHistory()
  // console.log(history, history.length)
  // global state
  const { isSidebarOpen, setIsSidebarOpen } = useApp()

  // local state
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)

  // routes array
  const routes = useMemo(
    () => [
      { id: 1, label: 'Users', route: '/users', icon: GroupIcon },
      { id: 2, label: 'Topics', route: '/topics', icon: ExtensionIcon },
      { id: 3, label: 'Topic Groups', route: '/topic-groups', icon: LayersIcon },
      { id: 4, label: 'Tags', route: '/tags', icon: LocalOfferIcon },
      {
        id: 5,
        label: 'Earnings',
        route: '/earnings',
        icon: AccountBalanceWalletIcon
      },
      { id: 6, label: 'Feedback', route: '/feedback', icon: FeedbackIcon },
      { id: 7, label: 'Languages', route: '/languages', icon: TranslateIcon },
      { id: 8, label: 'FAQs', route: '/faqs', icon: LiveHelpIcon },
      { id: 9, label: 'Maintenance', route: '/maintenance', icon: AccessAlarmIcon },
      {
        id: 10,
        label: 'Suggestions',
        route: '/suggestions',
        icon: DynamicFeedIcon
      },
      {
        id: 11,
        label: 'Logs',
        route: '/logs',
        icon: ReportIcon
      },
      {
        id: 12,
        label: 'Delete Requests',
        route: '/delete-requests',
        icon: DeleteIcon
      },
      {
        id: 13,
        label: 'Questions',
        route: '/questions',
        icon: ContactSupportIcon
      },
      {
        id: 14,
        label: 'Sample Questions',
        route: '/sample-questions',
        icon: QuestionAnswerIcon
      }
    ],
    []
  )

  const handleDrawer = () => setIsSidebarOpen((currState: boolean) => !currState)
  const handleMenuOpen = (event: React.MouseEvent) => setAnchorEl(event.currentTarget)
  const handleMenuClose = (): void => setAnchorEl(null)

  const onLogout = (): void => {
    handleMenuClose()

    // removing user data from localStorage
    localStorage.removeItem('user')
    localStorage.removeItem('token')

    // sending the user to /login
    window.location.href = '/login'
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isSidebarOpen
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>

          <Box flexGrow={1}>
            <Link className={classes.appBarTitleLink} to="/">
              <Box display="flex" alignItems="center">
                {/* <IconButton
                  onClick={() =>
                    history.length > 2
                      ? () => {
                          history.goBack()
                        }
                      : null
                  }
                  className={history.length <= 1 ? classes.hide : ''}
                >
                  <ChevronLeftIcon style={{ color: '#fff' }} />
                </IconButton> */}
                <img src={Logo} width={30} alt="Canguru Logo" />
                <Box width={10} />
                <Typography className={classes.appBarTitle} variant="h6" noWrap>
                  canguru
                </Typography>
              </Box>
            </Link>
          </Box>

          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              onClick={handleMenuOpen}
              color="inherit"
              className={classes.hide}
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen
          })
        }}
      >
        <div className={classes.toolbar}>
          <Link key={1} className={classes.listItem} to="/">
            <ListItem>
              {/* <ListItemIcon>
                <route.icon
                  color={route.route === location.pathname ? 'secondary' : 'inherit'}
                />
              </ListItemIcon> */}
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
        </div>

        <Divider />

        <List>
          {routes.map((route) => (
            <Link key={route.id} className={classes.listItem} to={route.route}>
              <ListItem
                button
                selected={route.route === location.pathname}
                classes={{ selected: classes.listItemActive }}
              >
                <ListItemIcon>
                  <route.icon
                    color={route.route === location.pathname ? 'secondary' : 'inherit'}
                  />
                </ListItemIcon>
                <ListItemText primary={route.label} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>

      {/* USER MENU */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

export default Navbar
