import { useEffect, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'

// ui elements
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

// types
import { IFaqModalValues, IFaqModal, IFaq } from 'types'

type PropTypes = {
  faqModal: IFaqModal
  faqEditData: IFaq | null
  toggleModal: () => void
  onAddOrEditFaq: (values: IFaqModalValues, mode: 'Add' | 'Edit') => void
}

const FAQModal = ({ faqModal, faqEditData, toggleModal, onAddOrEditFaq }: PropTypes) => {
  // react hook form
  const { handleSubmit, control, reset } = useForm({ mode: 'onChange' })

  const resetForm = useCallback(() => {
    reset({
      _id: '',
      group: 'GENERAL',
      en_question: '',
      it_question: '',
      en_answer: '',
      it_answer: ''
    })
  }, [reset])

  useEffect(() => {
    if (!faqModal.open) {
      resetForm()
    }

    if (faqModal.mode === 'Edit' && faqEditData) {
      reset({
        _id: faqEditData._id,
        group: faqEditData.group,
        en_question: faqEditData.question.en,
        it_question: faqEditData.question.it,
        en_answer: faqEditData.answer.en,
        it_answer: faqEditData.answer.it
      })
    }
  }, [faqModal, faqEditData, reset, resetForm])

  const addOrEditFaq = (values: IFaqModalValues): void => {
    onAddOrEditFaq(values, faqModal.mode)

    // closing the modal
    toggleModal()
  }

  return (
    <Dialog maxWidth="md" fullWidth open={faqModal.open} onClose={() => toggleModal()}>
      <DialogTitle>{faqModal.mode} an FAQ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the below details to {faqModal.mode.toLowerCase()} an FAQ
        </DialogContentText>

        <Box height={20} />

        <form onSubmit={handleSubmit(addOrEditFaq)} noValidate>
          <Grid spacing={1} container>
            <Grid item md={12}>
              <Controller
                name="group"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Group is required'
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Group</InputLabel>
                    <Select
                      fullWidth
                      required
                      label="Group"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                    >
                      <MenuItem value={'GENERAL'}>General</MenuItem>
                      <MenuItem value={'USER'}>User</MenuItem>
                      <MenuItem value={'GURU'}>Guru</MenuItem>
                    </Select>
                    <FormHelperText>{error && error.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>

            <Box marginTop={12} />

            <Grid item md={12}>
              <Typography style={{ fontWeight: 'bold' }} variant="body1">
                ENGLISH
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Controller
                name="en_question"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Question is required',
                  minLength: {
                    value: 2,
                    message: 'Question must should be at least 2 characters'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    margin="normal"
                    label="Question"
                    fullWidth
                    required
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="en_answer"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Answer is required',
                  minLength: {
                    value: 2,
                    message: 'Answer must should be at least 2 characters'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    margin="normal"
                    label="Answer"
                    fullWidth
                    required
                    multiline
                    rows={5}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>

            <Box marginTop={25} />

            <Grid item md={12}>
              <Typography style={{ fontWeight: 'bold' }} variant="body1">
                ITALIAN
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Controller
                name="it_question"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Question is required',
                  minLength: {
                    value: 2,
                    message: 'Question must should be at least 2 characters'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    margin="normal"
                    label="Question"
                    fullWidth
                    required
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="it_answer"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Answer is required',
                  minLength: {
                    value: 2,
                    message: 'Answer must should be at least 2 characters'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    margin="normal"
                    label="Answer"
                    fullWidth
                    required
                    multiline
                    rows={5}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box height={20} />

          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            disableElevation
            color="primary"
          >
            {faqModal.mode} FAQ
          </Button>

          <Box height={10} />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default FAQModal
