import { useState, useEffect, useCallback, ChangeEvent, useRef, Fragment } from 'react'
import { withRouter } from 'react-router-dom'

// ui elements
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Pagination from '@material-ui/lab/Pagination'

// components
import { FAQCard, FAQModal, AppButton } from 'components'

// HOCs
import Layout from 'HOCs/Layout'

// utils
import utils from 'utils'

// types
import { IFaqModal, IFaqModalValues, IRouteProps, IFaq } from 'types'

// styles
import styles from './styles'
import { Typography } from '@material-ui/core'

type PropTypes = IRouteProps<{}>

const FAQs = (props: PropTypes) => {
  // styles
  const classes = styles()

  // constants
  const RESULT_LIMIT: number = 15
  const DEBOUNCE_DURATION: number = 500

  // local state
  const [faqs, setFaqs] = useState<IFaq[] | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [faqEditData, setFaqEditData] = useState<IFaq | null>(null)
  const [selectedLang, setSelectedLang] = useState<string>('en')
  const [selectedGroup, setSelectedGroup] = useState<string>('GENERAL')
  const [faqModal, setFaqModal] = useState<IFaqModal>({
    open: false,
    mode: 'Add'
  })
  const [totalFaqs, setTotalFaqs] = useState<number>(0)
  const [searchTimeoutId, setSearchTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // refs
  const pageRef = useRef(1)
  const searchRef = useRef('')

  const fetchFaqs = useCallback(
    async (page?: number) => {
      setIsLoading(true)

      const faqData = await utils.REQ(
        'get',
        `${utils.EP.FAQS}?search=${
          searchRef.current
        }&group=${selectedGroup}&limit=${RESULT_LIMIT}&sort=asc&page=${
          page ?? pageRef.current
        }`
      )

      // setting current page number
      pageRef.current = faqData.page

      setIsLoading(false)
      setFaqs(faqData.data)
      setTotalFaqs(faqData.total)
    },
    [selectedGroup]
  )

  const onSearchFaqs = (value: string) => {
    setSearchQuery(value)
    searchRef.current = value

    if (searchTimeoutId) clearTimeout(searchTimeoutId)

    // setting page to 1 on search
    pageRef.current = 1

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      if (value.trim().length > 2) fetchFaqs()

      // if no query, fetch the topics from page 1
      if (value.trim().length === 0) fetchFaqs(1)
    }, DEBOUNCE_DURATION)

    setSearchTimeoutId(timeoutId)
  }

  useEffect(() => {
    fetchFaqs()
  }, [fetchFaqs])

  const toggleFaqModal = (mode: 'Add' | 'Edit' = 'Add'): void => {
    setFaqModal((currState: IFaqModal) => ({ mode, open: !currState.open }))
  }

  const onFaqAdd = (): void => {
    setFaqEditData(null)
    toggleFaqModal()
  }

  const onFaqEdit = (faqData: IFaq): void => {
    setFaqEditData(faqData)
    toggleFaqModal('Edit')
  }

  const onFaqDelete = async (faqData: IFaq): Promise<void> => {
    await utils.REQ('delete', `${utils.EP.FAQS_DELETE}/${faqData._id}`)
    fetchFaqs(pageRef.current)
  }

  const onAddOrEditFaq = async (
    values: IFaqModalValues,
    mode: 'Add' | 'Edit'
  ): Promise<void> => {
    const dataToSend = {
      group: values.group,
      question: { en: values.en_question, it: values.it_question },
      answer: { en: values.en_answer, it: values.it_answer }
    }

    if (mode === 'Add') {
      await utils.REQ('post', utils.EP.FAQS_CREATE, dataToSend)
    } else {
      await utils.REQ('put', utils.EP.FAQS_UPDATE, {
        id: faqEditData?._id,
        ...dataToSend
      })
    }

    await fetchFaqs(pageRef.current)
  }

  const onPageChange = (_: ChangeEvent<unknown>, page: number) => {
    fetchFaqs(page)
  }

  return (
    <Layout>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <TextField
            value={searchQuery}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchFaqs(e.target.value)}
            variant="outlined"
            fullWidth
            label="Search FAQs ..."
          />
        </Grid>

        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              fullWidth
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value as string)}
            >
              <MenuItem value={'GENERAL'}>General</MenuItem>
              <MenuItem value={'USER'}>User</MenuItem>
              <MenuItem value={'GURU'}>Guru</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              fullWidth
              value={selectedLang}
              onChange={(e) => setSelectedLang(e.target.value as string)}
            >
              <MenuItem value={'en'}>English</MenuItem>
              <MenuItem value={'it'}>Italian</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <AppButton onClick={onFaqAdd} className={classes.appButton}>
            Add FAQ
          </AppButton>
        </Grid>
      </Grid>

      <Box height={40} />

      <div className={classes.tableContainer}>
        {isLoading && (
          <Box height={40}>
            <Typography variant="body2">Loading...</Typography>
          </Box>
        )}

        {faqs && faqs.length
          ? faqs.map((faq: IFaq) => {
              return (
                <Fragment key={faq._id}>
                  <FAQCard
                    title={faq.question[selectedLang as 'en' | 'it']}
                    body={faq.answer[selectedLang as 'en' | 'it']}
                    onEdit={() => onFaqEdit(faq)}
                    onDelete={() => onFaqDelete(faq)}
                  />
                  <Box height={10} />
                </Fragment>
              )
            })
          : faqs &&
            faqs.length === 0 && (
              <Box
                display={'flex'}
                height={200}
                justifyContent="center"
                alignItems={'center'}
              >
                <Typography variant="h6">No FAQs found...</Typography>
              </Box>
            )}
      </div>

      {faqs && faqs.length ? (
        <Box display="flex" justifyContent="center">
          <Pagination
            color="primary"
            count={Math.ceil(totalFaqs / RESULT_LIMIT)}
            size="large"
            shape="rounded"
            onChange={onPageChange}
          />
        </Box>
      ) : null}

      {/* MODAL */}
      <FAQModal
        faqModal={faqModal}
        onAddOrEditFaq={onAddOrEditFaq}
        faqEditData={faqEditData}
        toggleModal={toggleFaqModal}
      />
    </Layout>
  )
}

export default withRouter(FAQs)
